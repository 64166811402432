"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Mixins } from "vue-property-decorator";
import NumberType from "./components/NumberType.vue";
import NumberConfig from "./components/NumberConfig.vue";
import chargeRuleDialog from "@/views/money/charge_rule_dialog.vue";
import moneyVerifyDialog from "@/views/money/money_verify.dialog.vue";
import API from "@ym-framework/api-utils";
import PrePurchaseInstructionsDialog from "./components/PrePurchaseInstructionsDialog.vue";
import Back from "@/components/back/Back.vue";
import ChargingRules from "./components/ChargingRules.vue";
import { AccountSessionMixins } from "@/utils/account-session";
import { buyProductService } from "./service/buyProducts.service";
import { AccountManager } from "@/utils/service/accountManager.service";
let buyMain = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.paymentAgree = false;
    this.titleTip = "\u8D2D\u4E70\u4E91\u53F7";
    // userStatus = localStorage.getItem('userStatus')
    // userFrozenReason = localStorage.getItem('userFrozenReason')
    this.buyProductService = new buyProductService();
    this.flag = false;
    this.ext_flag = false;
    this.eight_flag = true;
  }
  /**
   * 购买页流程:
   *  获取商品SKU 获取国家列表 → 根据国家列表获取号码地区列表 → 根据号码地区列表获取号码池列表
   *  →计算价格
   */
  mounted() {
    return __async(this, null, function* () {
      this.loading = true;
      yield this.buyProductService.search();
      yield this.computedPrice();
      this.loading = false;
    });
  }
  // 滚到顶部
  toBeforeBuy() {
    document.body.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }
  /**
   * 展示完整内容【购前说明】
   */
  displayFull() {
    this._prePurchaseInstructionsDialog.dialogVisible = true;
  }
  //获取金豆
  getCoin() {
    return __async(this, null, function* () {
      try {
        const accountService = new AccountManager();
        yield accountService.session();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  /**
   * 计算价格
   */
  computedPrice() {
    return __async(this, null, function* () {
      try {
        yield this.buyProductService.computePrice();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.loading = false;
      }
    });
  }
  authStatus() {
    return __async(this, null, function* () {
      try {
        let response = yield API.get(
          "api://base/api/v1/certification/status"
        );
        if (response.data.enterprise_status === "passed" && response.data.commitment_status === "passed") {
          return true;
        }
        return false;
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      }
    });
  }
  //生成订单
  createOrder() {
    return __async(this, null, function* () {
      if (!this.paymentAgree) {
        this.$alert("\u8BF7\u540C\u610F\u670D\u52A1\u6761\u6B3E", "\u63D0\u793A", {
          confirmButtonText: "\u786E\u5B9A"
        });
        return;
      }
      yield this.dayGreaterTwenty();
    });
  }
  dayGreaterTwenty() {
    return __async(this, null, function* () {
      if (new Date().getDate() >= 20) {
        this.$confirm(
          `\u4E91\u53F7\u7801\u6708\u79DF\u8D39\u7528\u6309\u81EA\u7136\u6708\u6536\u53D6\uFF0C\u6708\u672B\u8D2D\u4E70\u4ECD\u9700\u652F\u4ED8\u6574\u6708\u6708\u79DF\uFF0C\u5EFA\u8BAE\u6708\u521D\u8D2D\u4E70\u3002\u662F\u5426\u7EE7\u7EED\u8D2D\u4E70\uFF1F`,
          "\u63D0\u793A",
          {
            confirmButtonText: "\u786E\u5B9A",
            cancelButtonText: "\u53D6\u6D88",
            type: "warning"
          }
        ).then(() => __async(this, null, function* () {
          yield this.submitOrder();
        }));
      } else {
        yield this.submitOrder();
      }
    });
  }
  submitOrder() {
    return __async(this, null, function* () {
      try {
        this.loading = true;
        let request = {
          "type": "NUMBER",
          "payPlatform": this.buyProductService.paymentController.purchaseSelected.platform,
          "newNumber": {
            "number": this.buyProductService.paymentController.purchaseSelected.number,
            "productId": this.buyProductService.paymentController.purchaseSelected.products.code
          },
          "autoRenew": this.buyProductService.paymentController.purchaseSelected.isAutoRenew
        };
        let response = yield this.buyProductService.buildOrder(request);
        this._moneyVerifyDialog.open(response);
      } catch (error) {
        this.$message.error(error.response.data.errors.datas[0].message);
      } finally {
        this.loading = false;
      }
    });
  }
  // 返回路由
  goback() {
    this.$router.push("/main");
  }
  // 收费规则
  // getChargeRule() {
  //   this._chargeRuleDialog.open()
  // }
  // 服务条款
  clause() {
    window.open("/Terms");
  }
};
__decorateClass([
  Ref("charge-rule-dialog")
], buyMain.prototype, "_chargeRuleDialog", 2);
__decorateClass([
  Ref("money-verify-dialog")
], buyMain.prototype, "_moneyVerifyDialog", 2);
__decorateClass([
  Ref("pre-purchase-dialog")
], buyMain.prototype, "_prePurchaseInstructionsDialog", 2);
buyMain = __decorateClass([
  Component({
    components: {
      NumberType,
      NumberConfig,
      chargeRuleDialog,
      moneyVerifyDialog,
      PrePurchaseInstructionsDialog,
      Back,
      ChargingRules
    }
  })
], buyMain);
export {
  buyMain as default
};
