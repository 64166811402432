"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let storeInfoList = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.guid = "";
    //临时被叫号码ID
    this.shop_id = "";
    //店铺序列号
    this.shop_name = "";
    //店铺名称
    this.user_guid = "";
    //关联用户
    this.user_name = "";
    //关联用户名
    this.is_bind = 1;
    //是否可用 1可用2不可用
    this.is_bind_name = "";
    //是否绑定名称
    this.bind_time = 1600;
    //关联时间
    this.unbind_time = "xxxx";
    //解除时间
    this.unbind_user = "";
    //解除绑定人
    this.unbind_user_name = "";
    //解除绑定人名称
    this.platform = "";
    //所属平台
    this.platfrom_en = "";
    //多语言平台？
    this.cloud_phone = "";
    //绑定云号码
    this.cloud_phone_guid = "";
    //云号码ID
    this.remark = "";
  }
  //备注
};
storeInfoList.model = "phone.cloud.detail";
storeInfoList = __decorateClass([
  Annotation.ModelMeta
], storeInfoList);
