"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import UiForm, {
  UiFormAnnotations,
  UiFormController
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import i18n from "@/lang";
import API from "@ym-framework/api-utils";
export class RememberForm extends UiFormController {
  constructor() {
    super(...arguments);
    this.email = "";
    this.password = "";
    this.code = "";
  }
}
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("cloud_phone.login.form.email_rule"),
      trigger: "blur"
    },
    {
      validator: (rule, value, callback) => {
        if (value !== null) {
          let run = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          let search = value.search(run);
          if (value !== "" && search < 0) {
            return callback(new Error(i18n.t("cloud_phone.register.form.email_validator_rule")));
          }
        }
        return callback();
      },
      trigger: "blur"
    }
  ])
], RememberForm.prototype, "email", 2);
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("cloud_phone.login.form.password_rule"),
      trigger: "blur"
    },
    {
      validator: (rule, value, callback) => {
        if (value !== null) {
          let run = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_-])[A-Za-z\d@$!%*#?&_-]{8,32}$/;
          let search = value.search(run);
          if (value !== "" && search < 0) {
            return callback(new Error(i18n.t("cloud_phone.login.form.password_validator_rule")));
          }
        }
        return callback();
      },
      trigger: "blur"
    }
  ])
], RememberForm.prototype, "password", 2);
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("cloud_phone.login.form.code_rule"),
      trigger: "blur"
    }
  ])
], RememberForm.prototype, "code", 2);
let Remember = class extends Vue {
  constructor() {
    super(...arguments);
    this.logo = require("@/assets/style/images/logo.png");
    this.RememberFormController = new RememberForm();
    this.rememberLoading = false;
    this.time = 0;
    this.buttonLock = false;
  }
  mounted() {
    return __async(this, null, function* () {
      let lock = localStorage.getItem("rememberLock");
      if (lock) {
        let _lock = Number(lock);
        this.lock(_lock);
      }
    });
  }
  lock(time) {
    this.time = time;
    this.buttonLock = true;
    let countdown = () => {
      this.time--;
      let _time = this.time.toString();
      localStorage.setItem("rememberLock", _time);
      if (this.time <= 0) {
        this.buttonLock = false;
        localStorage.removeItem("rememberLock");
        clearInterval(countdownInterval);
      }
    };
    countdown.call(null);
    const countdownInterval = setInterval(countdown, 1e3);
  }
  clearLock() {
    this.time = 0;
  }
  remember() {
    return __async(this, null, function* () {
      try {
        this.rememberLoading = true;
        yield this._rememberForm._form.validate();
        yield API.put(`api://base.cloud/v1/password-reset`, {
          // phone: this.RememberFormController.phone,
          email: this.RememberFormController.email,
          password: this.RememberFormController.password,
          code: this.RememberFormController.code
        });
        this.$message.success(this.$i18n.t("cloud_phone.remember_success_message"));
        this.$router.push(`/login`);
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.rememberLoading = false;
      }
    });
  }
  toLogin() {
    this.$router.push(`/login`);
  }
  sendVerifyCode() {
    return __async(this, null, function* () {
      try {
        let validPhone = true;
        yield this._rememberForm._form.validateField("phone", (errorMessage) => {
          validPhone = errorMessage === "";
        });
        if (validPhone) {
          yield API.post(`api://base.cloud/v1/verification-code`, {
            // "phone":this.RememberFormController.phone,
            "email": this.RememberFormController.email,
            "type": "RESET_PASSWORD"
          });
          this.lock(120);
        }
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
};
__decorateClass([
  Ref("rememberForm")
], Remember.prototype, "_rememberForm", 2);
Remember = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], Remember);
export {
  Remember as default
};
