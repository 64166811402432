"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
import { handleDate } from "@/tools/date";
let sms_detail_dialog = class extends Vue {
  constructor() {
    super(...arguments);
    // HandleSmsDetail: HandleSmsDetail = new HandleSmsDetail();
    this.sms_detail_dialog = new UiDialogController();
    this.pictLoading = false;
    this.bthLoading = false;
    this.title = "\u8BE6\u60C5";
    this.smsInfo = {};
    this.detailInfo = {
      cloud_phone: "",
      //云号码
      sms_time: 0,
      //短信时间
      sender_number: "",
      //发送方
      receiver_number: "",
      //接受方
      message_pay: 0,
      //短信费用
      message: "",
      //短信内容
      number_pool: 1,
      // 号池
      message_length: 0
      //短信字数
    };
  }
  open(info) {
    return __async(this, null, function* () {
      this.smsInfo = info;
      this.detailInfo.cloud_phone = this.smsInfo.row.number;
      this.detailInfo.sms_time = this.smsInfo.row.smsTime;
      this.detailInfo.sender_number = this.smsInfo.row.sendNumber;
      this.detailInfo.receiver_number = this.smsInfo.row.receiveNumber;
      this.detailInfo.message_pay = this.smsInfo.smsInfo.price;
      this.detailInfo.message_length = this.smsInfo.smsInfo.wordNumber;
      this.detailInfo.message = this.smsInfo.smsInfo.content;
      this.sms_detail_dialog.visible = true;
    });
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date);
  }
  close() {
    this.sms_detail_dialog.visible = false;
  }
};
sms_detail_dialog = __decorateClass([
  Component({
    components: {
      UiDialog
    }
  })
], sms_detail_dialog);
export {
  sms_detail_dialog as default
};
