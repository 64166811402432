"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
import UiForm, {
  UiFormAnnotations,
  UiFormController
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
export class HandleRemark extends UiFormController {
  constructor() {
    super(...arguments);
    this.remark = "";
  }
}
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: false,
      message: "\u4E91\u53F7\u7801\u5907\u6CE8\u4E0D\u80FD\u4E3A\u7A7A",
      trigger: "blur"
    }
  ])
], HandleRemark.prototype, "remark", 2);
let remark_dialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.HandleRemark = new HandleRemark();
    this.remark_dialog = new UiDialogController();
    this.title = "";
    this.bthLoading = false;
    this.remarkData = {
      cloud_phone_guid: ""
    };
  }
  init() {
    this.remarkData.cloud_phone_guid = "";
  }
  open(row) {
    this.init();
    this.HandleRemark.remark = row.remark;
    this.remarkData.cloud_phone_guid = row.id;
    this.remark_dialog.visible = true;
    this.$nextTick(() => {
      this._UiForm._form.resetFields();
    });
  }
  close() {
    this.remark_dialog.visible = false;
    this.$emit("refresh");
  }
  submit() {
    return __async(this, null, function* () {
      this.bthLoading = true;
      try {
        yield API.put(
          `api://base.cloud.auth/v1/numbers/${this.remarkData.cloud_phone_guid}/remarks`,
          {
            remark: this.HandleRemark.remark
          }
        );
        this.$message({
          message: "\u64CD\u4F5C\u6210\u529F",
          type: "success"
        });
        this.close();
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      } finally {
        this.bthLoading = false;
      }
    });
  }
};
__decorateClass([
  Ref("form")
], remark_dialog.prototype, "_UiForm", 2);
remark_dialog = __decorateClass([
  Component({
    components: {
      UiDialog,
      UiForm,
      UiSubmit
    }
  })
], remark_dialog);
export {
  remark_dialog as default
};
