"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Mixins } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import Question from "@/components/Question.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { handleDate } from "@/tools/date";
import _ from "lodash";
import configAuthority from "./components/configAuthority.vue";
import allotCloudNumber from "./components/allotCloudNumber.vue";
import appAuth from "./components/appAuth.vue";
import BatchOperate from "@/components/elements/BatchOperate.vue";
import { teamBatch } from "@/configuration/constant/cloud";
import { MenuService } from "@/service/menu.service";
import { AccountSessionMixins } from "@/utils/account-session";
const _gridController = new GridController(
  new APIDataSource("api://base/v1/org/users")
);
let team = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.gridController = _gridController;
    this.teamBatch = teamBatch;
    // 激活名称 未用
    this.activeName = "";
    // 多选数组 未用
    this.muchSelectArr = [];
    this.batchHandle = true;
    //批量操作显示
    this.menuService = new MenuService();
    this.tableHight = this.menuService.defaultTableHeight();
    // 表格高度
    // 空数据配置项
    this.nullImg = require("@/assets/style/images/kong-vd.b11ecd8e.png");
    this.nullText = "\u6682\u65E0\u6570\u636E";
    // 请求表格的参数
    this.params = {
      search: "",
      sort: ""
      //排序use_date,asc;cloud_phone,desc
    };
    this.userStatus = localStorage.getItem("userStatus");
    this.userFrozenReason = localStorage.getItem("userFrozenReason");
    //   搜索
    this.searchEvent = _.debounce(() => {
      this.gridController.query.current = 1;
      this.search();
    }, 1e3);
  }
  created() {
    this.gridController.query.params = this.params;
  }
  mounted() {
    return __async(this, null, function* () {
      this.menuService.computedTableHeight("team_container", (height) => {
        this.tableHight = height;
      });
    });
  }
  // async Permissions() {
  //   const accountService = new AccountManager()
  //   let res = await accountService.Permissions()
  //   if (res) {
  //     this.userStatus = res.userStatus
  //     this.userFrozenReason = res.userFrozenReason
  //   }
  // }
  // 获取可用的批量操作
  // get teamBatchWitchPower(): BatchOperation[] {
  //   return this.teamBatch.filter((item) => {
  //     if (this.readAuthority(item.power)) {
  //       return item
  //     } else {
  //       return false
  //     }
  //   })
  // }
  //   单项更多点击
  handleCommand(data) {
  }
  //   顶部tabbar
  handleClick() {
  }
  // 关闭批量操作
  closeBatch() {
    this.batchHandle = !this.batchHandle;
    this._multipleTable.clearSelection();
  }
  // 多选选中
  handleSelectionChange(val) {
    if (val.length === 0) {
      this.batchHandle = true;
    } else {
      this.batchHandle = false;
    }
    this.muchSelectArr = [...val];
  }
  // 打开分配云号弹窗
  allot(row) {
    this._allotCloudNumber.title = "\u5206\u914D\u4E91\u53F7";
    this._allotCloudNumber.open(row);
  }
  // 打开配置权限弹窗
  configAuthorityEvent(row) {
    this._configAuthority.title = "\u914D\u7F6E\u6743\u9650";
    this._configAuthority.open(row);
  }
  batchCommand(val) {
    if (val === "yh-config-power") {
      this._configAuthority.title = "\u6279\u91CF\u914D\u7F6E\u6743\u9650";
      this._configAuthority.open();
      this._configAuthority.userId = "";
      this._configAuthority.batchUserId = this.muchSelectArr.map(
        (item) => item.id
      );
    }
  }
  //   刷新
  refreshList() {
    this.search();
  }
  authApp() {
    this._appAuth.title = "\u5E94\u7528\u6388\u6743";
    this._appAuth.open();
  }
  getRowKeys(row) {
    return row.id;
  }
  tableSort(row) {
    let sort = "desc";
    let field = row.prop;
    if (row.order === "descending") {
      sort = "desc";
    }
    if (row.order === "ascending") {
      sort = "asc";
    }
    if (!row.order) {
      sort = "";
      this.params.sort = "";
      this.search();
      return;
    }
    this.params.sort = `${field},${sort}`;
    this.search();
  }
  search(params) {
    return __async(this, null, function* () {
      yield this.gridController.search();
      this._multipleTable.clearSelection();
    });
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date * 1e3);
  }
};
__decorateClass([
  Ref("multipleTable")
], team.prototype, "_multipleTable", 2);
__decorateClass([
  Ref("config-authority")
], team.prototype, "_configAuthority", 2);
__decorateClass([
  Ref("allot-cloud-number")
], team.prototype, "_allotCloudNumber", 2);
__decorateClass([
  Ref("app-auth")
], team.prototype, "_appAuth", 2);
team = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid,
      Question,
      configAuthority,
      allotCloudNumber,
      appAuth,
      BatchOperate
    }
  })
], team);
export {
  team as default
};
