"use strict";
export const cloudInfoLog = (mock) => {
  mock.onGet(/\/api\/v1\/order\/\w+/).reply((config) => {
    let _response = {};
    _response = {
      "model": "order_detail_model",
      "order_guid": "String",
      // 订单id
      "order_type": "\u65B0\u8D2D",
      //订单类型
      "pay_mode": "\u91D1\u8C46",
      //支付方式
      "number_types": ["\u4E2D\u56FD", "\u865A\u62DF"],
      //云号配置
      "purchase_time": "3\u4E2A\u6708",
      //购买时长
      "purchase_num": "1",
      //数量
      "original_price": "12",
      //原价
      "pay_money": "11",
      //折后金额
      "discount_code": "\u65E0",
      //优惠券
      "time_left": "",
      //剩余时间
      "pay_coin": "16"
      //金豆
    };
    return [200, _response];
  });
};
