"use strict";
const TAG_STATUS = {
  ADEQUATE: "\u5145\u8DB3",
  NERVOUS: "\u7D27\u5F20",
  PRESELL: "\u9884\u552E"
};
export const TAG_STATUS_MAP = {
  [TAG_STATUS.ADEQUATE]: "primary",
  // 绿色
  [TAG_STATUS.NERVOUS]: "warning",
  // 黄色
  [TAG_STATUS.PRESELL]: "danger"
  // 蓝色
};
const NUMBER_POOL = {
  POOL_ONE: 1,
  POOL_TWO: 2,
  POOL_THREE: 3,
  POOL_FOUR: 4,
  POOL_FIVE: 5,
  POOL_SIX: 6,
  POOL_SEVEN: 7,
  POOL_EIGHT: 8
};
export const NUMBER_POOL_MAP = {
  [NUMBER_POOL.POOL_ONE]: "\u53F7\u6E90\u4E00",
  [NUMBER_POOL.POOL_TWO]: "\u53F7\u6E90\u4E8C",
  [NUMBER_POOL.POOL_THREE]: "\u53F7\u6E90\u4E09",
  [NUMBER_POOL.POOL_FOUR]: "\u53F7\u6E90\u56DB",
  [NUMBER_POOL.POOL_FIVE]: "\u53F7\u6E90\u4E94",
  [NUMBER_POOL.POOL_SIX]: "\u53F7\u6E90\u516D",
  [NUMBER_POOL.POOL_SEVEN]: "\u53F7\u6E90\u4E03",
  [NUMBER_POOL.POOL_EIGHT]: "\u53F7\u6E90\u516B"
};
export const NumberPoolList = [
  { name: NUMBER_POOL_MAP[1], code: "1" },
  { name: NUMBER_POOL_MAP[2], code: "2" },
  { name: NUMBER_POOL_MAP[3], code: "3" },
  { name: NUMBER_POOL_MAP[4], code: "4" },
  { name: NUMBER_POOL_MAP[5], code: "5" },
  { name: NUMBER_POOL_MAP[6], code: "6" },
  { name: NUMBER_POOL_MAP[7], code: "7" },
  { name: NUMBER_POOL_MAP[8], code: "8" }
];
export function signTagToNumberPool(quantity) {
  if (quantity > 0 && quantity < 100) {
    return TAG_STATUS.NERVOUS;
  } else if (quantity >= 100) {
    return TAG_STATUS.ADEQUATE;
  } else {
    return TAG_STATUS.PRESELL;
  }
}
