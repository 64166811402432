"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import Cookie from "js-cookie";
import UiForm, {
  UiFormAnnotations,
  UiFormController
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import i18n from "@/lang";
import { AccountManager } from "@/utils/service/accountManager.service";
export class LoginForm extends UiFormController {
  constructor() {
    super(...arguments);
    this.email = "";
    this.password = "";
  }
}
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("cloud_phone.login.form.email_rule"),
      trigger: "blur"
    },
    {
      validator: (rule, value, callback) => {
        if (value !== null) {
          let run = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          let search = value.search(run);
          if (value !== "" && search < 0) {
            return callback(new Error(i18n.t("cloud_phone.register.form.email_validator_rule")));
          }
          return callback();
        }
      },
      trigger: "blur"
    }
  ])
], LoginForm.prototype, "email", 2);
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("cloud_phone.login.form.password_rule"),
      trigger: "blur"
    },
    {
      validator: (rule, value, callback) => {
        if (value !== null) {
          let run = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_-])[A-Za-z\d@$!%*#?&_-]{8,32}$/;
          let search = value.search(run);
          if (value !== "" && search < 0) {
            return callback(new Error(i18n.t("cloud_phone.login.form.password_validator_rule")));
          }
        }
        return callback();
      },
      trigger: "blur"
    }
  ])
], LoginForm.prototype, "password", 2);
let Login = class extends Vue {
  constructor() {
    super(...arguments);
    this.logo = require("@/assets/style/images/logo.png");
    this.eyes = require("@/assets/style/images/eyes.png");
    this.eyes2 = require("@/assets/style/images/eyes2.png");
    this.LoginFormController = new LoginForm();
    this.loginLoading = false;
  }
  passwordFocus() {
    let img = document.querySelector(".eyes_for_you");
    img == null ? void 0 : img.setAttribute("class", "eyes_for_you eyes_look_out");
    let img2 = document.querySelector(".eyes_for_you2");
    img2 == null ? void 0 : img2.setAttribute("class", "eyes_for_you2 eyes_look_out2");
  }
  passwordBlur() {
    let img = document.querySelector(".eyes_for_you");
    img == null ? void 0 : img.setAttribute("class", "eyes_for_you");
    let img2 = document.querySelector(".eyes_for_you2");
    img2 == null ? void 0 : img2.setAttribute("class", "eyes_for_you2");
  }
  mounted() {
    return __async(this, null, function* () {
      yield this.checkToken();
    });
  }
  checkToken() {
    return __async(this, null, function* () {
      const accountService = new AccountManager();
      const flag = yield accountService.checkToken();
      if (flag) {
        this.$router.push("/main");
      }
    });
  }
  toRegister() {
    this.$router.push("/register");
  }
  toRemember() {
    this.$router.push("/remember");
  }
  getSession() {
    return __async(this, null, function* () {
      try {
        const accountService = new AccountManager();
        yield accountService.session();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  login() {
    return __async(this, null, function* () {
      try {
        this.loginLoading = true;
        yield this._loginForm._form.validate();
        let response = yield API.post(`api://base.cloud/v1/auth`, {
          // phone: this.LoginFormController.phone,
          email: this.LoginFormController.email,
          password: this.LoginFormController.password
        });
        Cookie.set("cloudNumberToken", response.data.token, { expires: 7 });
        yield this.getSession();
        this.$router.push(`/main`);
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.loginLoading = false;
      }
    });
  }
};
__decorateClass([
  Ref("loginForm")
], Login.prototype, "_loginForm", 2);
Login = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], Login);
export {
  Login as default
};
