"use strict";
export const numberListMock = (mock) => {
  mock.onGet("/api/v1/phones").reply((config) => {
    let _response = {};
    if (config.params.is_pased === 0 && config.params.is_entity === 0) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "phone.cloud",
              "guid": "888888888888888888",
              //云号码ID
              "cloud_phone": "16666666666",
              //云号码
              "cloud_config": "\u4E2D\u56FD\u79FB\u52A8-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 2,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "unsubscribe",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u5DF2\u9000\u8BA2",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 2,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u5E97\u94FA,\u5E97\u94FA,\u5E97\u94FA",
              //关联店铺名称，全部返回
              "remark": "\u5168\u90E8",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            },
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u4E2D\u56FD\u79FB\u52A8-\u865A\u62DF",
              //号码配置
              "entity_phone": "",
              //关联主号码
              "is_recording_enabled": 1,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 1,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u5E97\u94FA,\u5E97\u94FA,\u5E97\u94FA",
              //关联店铺名称，全部返回
              "remark": "\u5168\u90E8",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            },
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u4E2D\u56FD\u79FB\u52A8-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 1,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "arrearage",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B20\u8D39\u4FDD\u53F7",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 2,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6",
              //关联店铺名称，全部返回
              "remark": "\u5168\u90E8",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    if (config.params.is_pased === 2) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u4E2D\u56FD\u968F\u673A-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 2,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 2,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u5E97\u94FA,\u5E97\u94FA,\u5E97\u94FA",
              //关联店铺名称，全部返回
              "remark": "\u5373\u5C06\u8FC7\u671F",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            },
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u4E2D\u56FD\u968F\u673A-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 1,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 2,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u5E97\u94FA,\u5E97\u94FA,\u5E97\u94FA",
              //关联店铺名称，全部返回
              "remark": "\u5373\u5C06\u8FC7\u671F",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            },
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u4E2D\u56FD\u968F\u673A-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 2,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 1,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6",
              //关联店铺名称，全部返回
              "remark": "\u5373\u5C06\u8FC7\u671F",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    if (config.params.is_entity === 2) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u5317\u4EAC-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 2,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 2,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u5E97\u94FA,\u5E97\u94FA,\u5E97\u94FA",
              //关联店铺名称，全部返回
              "remark": "\u672A\u7ED1\u5B9A\u5B9E\u4F53\u53F7",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            },
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u5317\u4EAC-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 1,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 1,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u5E97\u94FA,\u5E97\u94FA,\u5E97\u94FA",
              //关联店铺名称，全部返回
              "remark": "\u672A\u7ED1\u5B9A\u5B9E\u4F53\u53F7",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            },
            {
              "model": "phone.cloud",
              "guid": "123456",
              //云号码ID
              "cloud_phone": "133333333333",
              //云号码
              "cloud_config": "\u5317\u4EAC-\u865A\u62DF",
              //号码配置
              "entity_phone": "1666666666",
              //关联主号码
              "is_recording_enabled": 2,
              //录音开启或关闭 1开启2关闭
              "use_date": 12,
              //可用天数
              "cloud_expire_time": 16221312e5,
              //云号到期时间
              "cloud_status": "normal",
              //normal：正常，unsubscribe：退订，arrearage：欠费保号
              "cloud_status_name": "\u6B63\u5E38",
              //状态名称  正常、已退订、欠费保号
              "is_auto_renewal": 2,
              //是否自动续费
              "temporary_called": "588888888888",
              //临时被叫号码
              "temporary_expire_time": 16221312e5,
              //临时被叫到期时间
              "shop_guid": "1,2,3",
              //关联店铺ID
              "shop_guid_name": "\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6,\u6D4B\u8BD5\u5E97\u94FA\u957F\u5EA6",
              //关联店铺名称，全部返回
              "remark": "\u672A\u7ED1\u5B9A\u5B9E\u4F53\u53F7",
              //备注
              "start_time": 16221312e5,
              //启用时间
              "is_use": "",
              //是否启用
              "user_guid": ""
              //所属主账号用户ID
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    return [200, _response];
  });
  mock.onGet("/api/v1/phone/number/all").reply((config) => {
    let _response = {
      "model": "operation",
      "messages": "success",
      "result": {
        "model": "phone.number.all",
        "is_pased_number": 66,
        //即将过期号码数量
        "is_entities_number": 88
        //未绑定实体号数量
      }
    };
    return [200, _response];
  });
};
