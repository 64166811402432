"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { CoreAccessRule } from "@/framework/access-control-lists";
import Cookie from "js-cookie";
import { AccountManager } from "@/utils/service/accountManager.service";
export class GuestAccess extends CoreAccessRule {
  next(to, from, next) {
    next();
  }
}
export class LogoutAccess extends CoreAccessRule {
  next(to, from, next) {
    Cookie.remove("cloudNumberToken");
    next("/login");
  }
}
export class AuthAccess extends CoreAccessRule {
  next(to, from, next) {
    return __async(this, null, function* () {
      try {
        const accountService = new AccountManager();
        yield accountService.session();
        next();
      } catch (error) {
        next("/login");
      }
    });
  }
}
