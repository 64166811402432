"use strict";
import { Options as APIOptions } from "@ym-framework/api-utils";
import PluginMock from "@ym-framework/api-utils-mock";
import { mocks } from "@/mock";
import Cookie from "js-cookie";
import { PluginRequestIdHeader } from "@ym-framework/api-utils";
import router from "@/router";
let APIUrl = "https://api.yunhao.shop/";
if (process.env.YM_LOCAL_MOCK) {
  const _mocks = mocks;
  APIOptions.plugins.install(new PluginMock((mock) => {
    for (const _mock of _mocks) {
      _mock(mock);
    }
  }));
}
APIOptions.plugins.install(new PluginRequestIdHeader());
class PluginTokenFail {
  constructor() {
    this.responseRejected = (error) => {
      var _a, _b, _c;
      if (error.hasOwnProperty("response")) {
        const code = (_a = error.response) == null ? void 0 : _a.data.errors.datas[0].code;
        if (code === "token.error") {
          Cookie.remove("cloudNumberToken");
          router.replace("/login");
        }
      }
      if (((_b = error.response) == null ? void 0 : _b.status) === 401 && ((_c = error.response) == null ? void 0 : _c.data.errors.datas[0].code) === "check.certification.error") {
        Cookie.remove("cloudNumberToken");
        router.replace("/login");
      }
      return error;
    };
  }
}
APIOptions.plugins.install(new PluginTokenFail());
APIOptions.host.set("base.cloud", () => {
  return {
    baseURL: APIUrl,
    headers: {
      "Accept-Language": localStorage.getItem("lang") || "zh-CN"
    }
  };
});
APIOptions.host.set("base.cloud.auth", () => {
  return {
    baseURL: APIUrl,
    headers: {
      "Authorization": Cookie.get("cloudNumberToken") || "",
      "Accept-Language": localStorage.getItem("lang") || "zh-CN"
    }
  };
});
APIOptions.host.set("base.cloud.file", () => {
  return {
    baseURL: APIUrl,
    responseType: "blob",
    headers: {
      "Authorization": Cookie.get("cloudNumberToken") || "",
      "Accept-Language": localStorage.getItem("lang") || "zh-CN"
    }
  };
});
