"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let TeamUsersModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.id = "";
    // 员工id
    this.name = "\u5458\u5DE50";
    // 员工姓名
    this.phone_num = 0;
    //可分配云号数量
    this.user_no = "0000";
  }
  // 用户编号
};
TeamUsersModel.model = "user.model";
TeamUsersModel = __decorateClass([
  Annotation.ModelMeta
], TeamUsersModel);
