"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, PropSync, Ref, Vue } from "vue-property-decorator";
import UiDialog from "@/components/dialog/UiDialog.vue";
import _ from "lodash";
import Collector from "@/components/dialog/Collector.vue";
let DistributionDialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialogVisible = false;
    this.collectorData = [];
  }
  created() {
  }
  mounted() {
  }
  add(itme) {
    this._collector.add(itme);
  }
  del(itme) {
    this._collector.del(itme);
  }
  changed(data) {
    this.collectorData = data;
    this.$emit("changed", data);
  }
  getAll() {
    return this._collector.getAll();
  }
  clear() {
    this._collector.clear();
  }
  save() {
    this.$emit("saved", this._collector.getAll());
  }
  syncData(allData, selectData, idName = "id", labelName = "name") {
    let unSelectData = _.differenceWith(
      allData,
      selectData,
      _.isEqual
    );
    for (const item of selectData) {
      const _item = {
        id: item[idName],
        label: item[labelName]
      };
      this._collector.add(_item);
    }
    for (const item of unSelectData) {
      const _item = {
        id: item[idName],
        label: item[labelName]
      };
      this._collector.del(_item);
    }
  }
  open() {
    this.dialogVisible = true;
    this.$nextTick(() => {
      this._collector.clear();
    });
  }
  close() {
    this.dialogVisible = false;
    this.$emit("refresh");
  }
  refresh() {
  }
};
__decorateClass([
  PropSync("bthLoading", { type: Boolean, default: () => false })
], DistributionDialog.prototype, "_bthLoading", 2);
__decorateClass([
  Ref("collector")
], DistributionDialog.prototype, "_collector", 2);
DistributionDialog = __decorateClass([
  Component({
    components: {
      UiDialog,
      Collector
    }
  })
], DistributionDialog);
export {
  DistributionDialog as default
};
