"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
let LangSelect = class extends Vue {
  constructor() {
    super(...arguments);
    this.language = localStorage.getItem("lang");
  }
  mounted() {
    return __async(this, null, function* () {
      this.getlanguage();
    });
  }
  getlanguage() {
    this.language = localStorage.getItem("lang");
  }
  setlanguage(lang) {
    if (lang !== "" && lang !== null && lang !== void 0) {
      this.handleSetLanguage(lang);
    }
  }
  handleSetLanguage(lang) {
    this.$i18n.locale = lang;
    localStorage.setItem("lang", lang);
    document.documentElement.lang = lang;
    this.getlanguage();
    this.$emit("changedLang");
    this.$message({
      message: this.$t("components.changeLanguageTips").toString(),
      type: "success"
    });
  }
};
LangSelect = __decorateClass([
  Component({
    name: "language"
  })
], LangSelect);
export {
  LangSelect as default
};
