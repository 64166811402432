"use strict";
export class MenuService {
  // 兼容tag
  compatibleWithTag(routeTag) {
    let target = null;
    const tag = routeTag;
    if (tag) {
      if (tag === "yh-all" || tag === "all") {
        target = "yh-all";
      }
      if (tag === "yh-expire" || tag === "expire") {
        target = "yh-expire";
      }
      if (tag === "yh-unbound" || tag === "unbound") {
        target = "yh-unbound";
      }
      if (tag === "yh-unsubscribe" || tag === "unsubscribe") {
        target = "yh-unsubscribe";
      }
    }
    return target;
  }
  // 云号查询参数
  queryMainParam(tag) {
    let param = "";
    if (tag === "all" || tag === "yh-all") {
      param = "all";
    }
    if (tag === "expire" || tag === "yh-expire") {
      param = "expire";
    }
    if (tag === "unbound" || tag === "yh-unbound") {
      param = "unbound";
    }
    if (tag === "unsubscribe" || tag === "yh-unsubscribe") {
      param = "unsubscribe";
    }
    return param;
  }
  // 详细计算计算表格高度根据侧边栏高度
  computedTableHeight(id, callback) {
    var _a, _b;
    const asideHeight = document.getElementById("yh-aside-menu").offsetHeight;
    const layoutOffsetTop = (_a = document.getElementById("layout-content")) == null ? void 0 : _a.offsetTop;
    const idPageOffsetTop = (_b = document.getElementById(id)) == null ? void 0 : _b.offsetTop;
    const h = idPageOffsetTop > layoutOffsetTop ? idPageOffsetTop - layoutOffsetTop : idPageOffsetTop;
    callback(asideHeight - h);
  }
  // 默认高度
  defaultTableHeight(config) {
    var _a, _b;
    const asideHeight = (_b = (_a = document.getElementById("yh-aside-menu")) == null ? void 0 : _a.offsetHeight) != null ? _b : 735;
    return !(config == null ? void 0 : config.header) ? asideHeight : asideHeight - 42;
  }
}
