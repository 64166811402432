"use strict";
import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/Main.vue";
import Record from "@/views/Record.vue";
import { AuthAccess, GuestAccess, LogoutAccess } from "@/access-rules";
import Renewal from "@/views/buyModule/Renewal.vue";
import buyMain from "@/views/buyModule/BuyMain.vue";
import Error from "@/views/Error.vue";
import Terms from "@/views/terms.vue";
import orderManage from "@/views/orderManage.vue";
import teamManage from "@/views/teamManage.vue";
import PaymentConfirm from "@/views/buyModule/components/PaymentConfirm.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Remember from "@/views/Remember.vue";
import Certification from "@/views/Certification.vue";
import Recharge from "@/views/Recharge.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/Terms",
    component: Terms,
    meta: {
      access: new AuthAccess(),
      title: "\u9CA8\u9C7C\u4E91\u53F7\u9690\u79C1\u4FDD\u62A4\u534F\u8BAE   \u2014  \u9CA8\u9C7C\u4E91\u53F7"
    }
  },
  {
    path: "/",
    redirect: "/login",
    meta: {
      title: "\u9CA8\u9C7C\u4E91\u53F7"
    }
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "404"
    },
    component: Error
  },
  {
    path: "/main",
    name: "Main",
    meta: {
      access: new AuthAccess(),
      title: "\u6211\u7684\u4E91\u53F7\u7801   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "main"
    },
    component: Main
  },
  {
    path: "/costmanage",
    name: "costmanage",
    meta: {
      access: new AuthAccess(),
      title: "\u8BA2\u5355\u7BA1\u7406   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "costmanage"
    },
    component: orderManage
  },
  {
    path: "/teammanage",
    name: "teammanage",
    meta: {
      access: new AuthAccess(),
      title: "\u56E2\u961F\u7BA1\u7406   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "teammanage"
    },
    component: teamManage
  },
  {
    path: "/renewal",
    name: "Renewal",
    meta: {
      access: new AuthAccess(),
      title: "\u7EED\u8D39\u4E91\u53F7   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "main"
    },
    component: Renewal
  },
  {
    path: "/buyMain",
    name: "buyMain",
    meta: {
      access: new AuthAccess(),
      title: "\u8D2D\u4E70\u4E91\u53F7   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "main"
    },
    component: buyMain
  },
  {
    path: "/payconfirm",
    name: "PayConfirm",
    meta: {
      access: new AuthAccess(),
      title: "\u652F\u4ED8\u7ED3\u679C   \u2014  \u9CA8\u9C7C\u4E91\u53F7"
    },
    component: PaymentConfirm
  },
  {
    path: "/record",
    name: "Record",
    meta: {
      access: new AuthAccess(),
      title: "\u4E91\u540C\u6B65\u8BB0\u5F55   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "record"
    },
    component: Record
  },
  {
    path: "/recharge",
    name: "Recharge",
    meta: {
      access: new AuthAccess(),
      title: "\u5145\u503C   \u2014  \u5F00\u9CB8\u4E91\u53F7",
      menuactive: "recharge"
    },
    component: Recharge
  },
  {
    path: "/certification",
    name: "Certification",
    meta: {
      access: new AuthAccess(),
      title: "\u4F01\u4E1A\u8BA4\u8BC1   \u2014  \u9CA8\u9C7C\u4E91\u53F7",
      menuactive: "certification"
    },
    component: Certification
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      access: new GuestAccess(),
      title: "\u9CA8\u9C7C\u4E91\u53F7"
    },
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      access: new GuestAccess(),
      title: "\u9CA8\u9C7C\u4E91\u53F7"
    },
    component: Register
  },
  {
    path: "/remember",
    name: "Remember",
    meta: {
      access: new GuestAccess(),
      title: "\u9CA8\u9C7C\u4E91\u53F7"
    },
    component: Remember
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      access: new LogoutAccess(),
      title: "\u9000\u51FA\u767B\u5F55"
    }
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
router.beforeEach((to, from, next) => {
  var _a, _b;
  let _access = new GuestAccess();
  if ((_a = to.meta) == null ? void 0 : _a.access) {
    _access = to.meta.access;
  }
  if ((_b = to.meta) == null ? void 0 : _b.title) {
    document.title = to.meta.title;
  }
  _access.next(to, from, next);
});
export default router;
