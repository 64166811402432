var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"NumberType br-10"},[_c('div',{staticClass:"numberType_content"},[(_vm._paymentController.purchaseList)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm._paymentController.loading),expression:"_paymentController.loading"}],attrs:{"element-loading-background":"rgba(255, 255, 255, 0.1)"}},[_c('el-row',[_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("@/assets/style/images/area.png"),"alt":""}}),_c('span',[_vm._v("国家")])])]),_c('el-col',{attrs:{"span":21}},[_c('div',{staticClass:"list-wrap"},[_c('el-radio-group',{on:{"change":function($event){return _vm.changeConfig(
                  'areas',
                  _vm._paymentController.purchaseSelected.regions.code
                )}},model:{value:(_vm._paymentController.purchaseSelected.regions),callback:function ($$v) {_vm.$set(_vm._paymentController.purchaseSelected, "regions", $$v)},expression:"_paymentController.purchaseSelected.regions"}},_vm._l((_vm._paymentController.purchaseList
                  .regions),function(item,index){return _c('el-radio-button',{key:index,attrs:{"label":item}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)])],1),_c('el-row',[_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("@/assets/style/images/area.png"),"alt":""}}),_c('span',[_vm._v("地区")])])]),_c('el-col',{attrs:{"span":21}},[_c('div',{staticClass:"list-wrap"},[_c('el-radio-group',{on:{"change":function($event){return _vm.changeConfig(
                  'types',
                  _vm._paymentController.purchaseSelected.areas.code
                )}},model:{value:(_vm._paymentController.purchaseSelected.areas),callback:function ($$v) {_vm.$set(_vm._paymentController.purchaseSelected, "areas", $$v)},expression:"_paymentController.purchaseSelected.areas"}},_vm._l((_vm._paymentController.purchaseList.areas),function(item,index){return _c('el-radio-button',{key:index,attrs:{"label":item}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)])],1),_c('el-row',[_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("@/assets/style/images/type.png"),"alt":""}}),_c('span',[_vm._v("类型")])])]),_c('el-col',{attrs:{"span":21}},[_c('div',{staticClass:"list-wrap"},[_c('el-radio-group',{on:{"change":function($event){return _vm.changeConfig(
                  'sources',
                  _vm._paymentController.purchaseSelected.types.code
                )}},model:{value:(_vm._paymentController.purchaseSelected.types),callback:function ($$v) {_vm.$set(_vm._paymentController.purchaseSelected, "types", $$v)},expression:"_paymentController.purchaseSelected.types"}},_vm._l((_vm._paymentController.purchaseList.types),function(item,index){return _c('el-radio-button',{key:index,attrs:{"label":item}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)])],1),_c('el-row',[_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("@/assets/style/images/numberpool.png"),"alt":""}}),_c('span',{staticClass:"number-pool"},[_vm._v("号源")])])]),_c('el-col',{attrs:{"span":21}},[_c('div',{staticClass:"list-wrap"},[_c('el-radio-group',{on:{"change":function($event){return _vm.changeConfig(
                  'products',
                  _vm._paymentController.purchaseSelected.sources.code
                )}},model:{value:(_vm._paymentController.purchaseSelected.sources),callback:function ($$v) {_vm.$set(_vm._paymentController.purchaseSelected, "sources", $$v)},expression:"_paymentController.purchaseSelected.sources"}},_vm._l((_vm._paymentController.purchaseList
                  .sources),function(item,index){return _c('el-radio-button',{key:index,attrs:{"label":item}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)])],1)],1):_vm._e(),_c('el-row',[(_vm._paymentController.purchaseSelected)?_c('el-col',{attrs:{"offset":3,"span":21}},[(_vm._paymentController.purchaseSelected.areas)?_c('span',[_vm._v(_vm._s(_vm._paymentController.purchaseSelected.areas.name)+"-"+_vm._s(_vm._paymentController.purchaseSelected.regions.name)+"-"+_vm._s(_vm._paymentController.purchaseSelected.types.name)+"-"+_vm._s(_vm._paymentController.purchaseSelected.sources.name)+" "),(
              _vm._paymentController.purchaseSelected.types &&
              _vm._paymentController.purchaseSelected.sources &&
              _vm._paymentController.purchaseSelected.types.name === '虚商' &&
              _vm._paymentController.purchaseSelected.sources.name !== '号源三'
            )?_c('question',{staticStyle:{"color":"#606266"},attrs:{"placement":"top-start"}},[_c('template',{slot:"ques_content"},[_vm._v(" 虚拟供应商，依靠租用传统电信运营商的通信资源开展电信业务的新型电信运营商。 "),_c('br'),_vm._v("目前可提供170、171、165、167等开头号码 ")])],2):_vm._e()],1):_vm._e()]):_vm._e()],1),_c('el-row',[_c('el-col',{attrs:{"offset":3,"span":21}},[(_vm._paymentController.purchaseSelected.sources)?_c('div',{staticStyle:{"line-height":"20px","padding":"2px 5px","background":"#f4f4f5","border-radius":"5px","color":"#9ea1a9","font-size":"13px"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("说明：云号码不支持拨打电话或发送短信，只能接收来自跨境电商运营相关平台的短信;云号码只能和归属地国家的号码互通短信;接收短信时云号码会携带["+_vm._s(_vm._paymentController.purchaseSelected.sources.name === "号源三" ? "鲨鱼云号" : "号码隐私保护")+"]的签名 ")]):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }