"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import _ from "lodash";
import { buyProductAPI } from "../api/buyProduct.api";
export class buyProductService {
  constructor() {
    ///商品列表
    this.paymentController = new PaymentController();
    /// 订单数据
    this.orderService = new OrderService();
    ///价格
    this.paymentCost = {
      loading: true,
      nowPrice: 0,
      originPrice: 0
    };
  }
  ///获取商品列表
  search() {
    return __async(this, null, function* () {
      yield this.paymentController.search();
    });
  }
  ///计算价格
  computePrice() {
    return __async(this, null, function* () {
      this.paymentCost.loading = true;
      let request = {
        "type": "NUMBER",
        "payPlatform": this.paymentController.purchaseSelected.platform,
        "newNumber": {
          "number": this.paymentController.purchaseSelected.number,
          "productId": this.paymentController.purchaseSelected.products.code
        }
      };
      let orderPrice;
      orderPrice = yield this.orderService.computePrice(request);
      this.paymentCost.nowPrice = orderPrice.nowPrice;
      this.paymentCost.originPrice = orderPrice.originPrice;
      this.paymentCost.loading = false;
    });
  }
  ///创建订单
  buildOrder(data) {
    return __async(this, null, function* () {
      const result = yield this.orderService.buildOrder(data);
      return result;
    });
  }
  // 服务中获取列表
  // async getList(req: CloudDesktopReqDto) {
  //     const list = await cloudDesktopAPI.getList(req);
  //     return list;
  // }
}
export class PaymentController {
  constructor() {
    // regions -> areas -> types -> sources -> products
    ///商品服务
    this.productData = new ProductService();
    ///是否加载中
    this.loading = false;
    ///实际展示配置列表
    this.purchaseList = {
      areas: [],
      products: [],
      regions: [],
      sources: [],
      types: []
    };
    ///购买的选中配置
    this.purchaseSelected = {
      areas: null,
      products: null,
      regions: null,
      sources: null,
      types: null,
      number: 1,
      platform: "COIN",
      isAutoRenew: false
    };
  }
  // 初始化商品列表
  init() {
    return __async(this, null, function* () {
      yield this.productData.init();
    });
  }
  //根据pid反向修改参数
  // async recommandchangeConfig(configName: string, configId: string) {
  //     const flag = await this.changeProductData(configName, configId);
  //     if (flag === false) {
  //       await this.changeConfig("regions", "");
  //       return;
  //     }
  //     switch (configName) {
  //       case "products":
  //         return;
  //       case "methods":
  //         await this.changeConfig("products", configId);
  //         return;
  //       case "specifications":
  //         await this.changeConfig("methods", configId);
  //         return;
  //       case "systems":
  //         await this.changeConfig("specifications", configId);
  //         return;
  //       case "areas":
  //         await this.changeConfig("systems", configId);
  //         return;
  //       case "regions":
  //         await this.changeConfig("areas", configId);
  //         return;
  //     }
  // }
  // 根据ID修改配置信息regions -> areas -> types -> sources -> products
  changeProductData(configName, configId) {
    return __async(this, null, function* () {
      let _configList = /* @__PURE__ */ new Set();
      switch (configName) {
        case "products":
          let product_item = this.productData.getData("products", configId);
          if (!product_item) {
            return false;
          }
          this.purchaseSelected[configName] = product_item;
          _configList = this.productData.getRelationDatas("sources", product_item.parentCode);
          this.purchaseList[`${configName}`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          return true;
        case "sources":
          let source_item = this.productData.getData("sources", configId);
          if (!source_item) {
            return false;
          }
          this.purchaseSelected[configName] = source_item;
          _configList = this.productData.getRelationDatas(
            "types",
            product_item.parentCode
          );
          this.purchaseList[`${configName}`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          return true;
        case "types":
          let type_item = this.productData.getData("types", configId);
          if (!type_item) {
            return false;
          }
          this.purchaseSelected[configName] = type_item;
          _configList = this.productData.getRelationDatas(
            "areas",
            product_item.parentCode
          );
          this.purchaseList[`${configName}`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          return true;
        case "areas":
          let area_item = this.productData.getData("areas", configId);
          if (!area_item) {
            return false;
          }
          this.purchaseSelected[configName] = area_item;
          _configList = this.productData.getRelationDatas(
            "regions",
            product_item.parentCode
          );
          this.purchaseList[`${configName}`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          return true;
        case "regions":
          let region_item = this.productData.getData("regions", configId);
          if (!region_item) {
            return false;
          }
          this.purchaseSelected[configName] = region_item;
          let _configListMap = this.productData.getDatas("regions");
          _configList = _configListMap.values();
          this.purchaseList[`${configName}`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          return true;
      }
    });
  }
  // 改变某个配置 regions -> areas -> types -> sources -> products
  changeConfig(configName, id) {
    return __async(this, null, function* () {
      let _configList = /* @__PURE__ */ new Set();
      switch (configName) {
        case "regions":
          _configList = this.productData.getDatas("regions").values();
          this.getConfigList(configName, _configList);
          yield this.changeConfig("areas", this.purchaseSelected.regions.code);
          return;
        case "areas":
          _configList = this.productData.getRelationDatas("regions", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("types", this.purchaseSelected.areas.code);
          return;
        case "types":
          _configList = this.productData.getRelationDatas("areas", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("sources", this.purchaseSelected.types.code);
          return;
        case "sources":
          _configList = this.productData.getRelationDatas("types", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("products", this.purchaseSelected.sources.code);
          return;
        case "products":
          _configList = this.productData.getRelationDatas("sources", id);
          this.getConfigList(configName, _configList);
          return;
        default:
          return;
      }
    });
  }
  // 获取修改配置后的配置列表
  getConfigList(configName, _configList) {
    for (const _configName in this.purchaseList) {
      if (_configName === configName) {
        this.purchaseList[_configName] = _.sortBy(
          Array.from(_configList),
          (item) => item.sort
        );
        let selected = false;
        for (const item of this.purchaseList[_configName]) {
          if (item.marks && item.marks.tags && item.marks.tags.includes("DEFAULT_SELECTED")) {
            this.purchaseSelected[configName] = item;
            selected = true;
            break;
          }
        }
        if (selected === false) {
          this.purchaseSelected[configName] = this.purchaseList[_configName][0];
        }
      }
    }
  }
  //获取配置列表
  search() {
    return __async(this, null, function* () {
      this.loading = true;
      yield this.init();
      yield this.changeConfig("regions", "");
      this.loading = false;
    });
  }
}
export class ProductService {
  constructor() {
    ///商品数据
    this._data = /* @__PURE__ */ new Map();
    ///层级关系 regions -> areas -> types -> sources -> products
    this._relations = /* @__PURE__ */ new Map();
  }
  init() {
    return __async(this, null, function* () {
      try {
        const productList = yield buyProductAPI.getProductList();
        for (const _dataType in productList) {
          this._data.set(_dataType, /* @__PURE__ */ new Map());
          for (const _dataItem of _.get(productList, _dataType)) {
            this._data.get(_dataType).set(_dataItem.code, _dataItem);
            if (!_dataItem.parentCode) {
              continue;
            }
            let _relationsName = "regions";
            if (_dataType === "areas") {
              _relationsName = "regions";
            }
            if (_dataType === "types") {
              _relationsName = "areas";
            }
            if (_dataType === "sources") {
              _relationsName = "types";
            }
            if (_dataType === "products") {
              _relationsName = "sources";
            }
            if (!this._relations.has(_relationsName)) {
              this._relations.set(_relationsName, /* @__PURE__ */ new Map());
            }
            if (!this._relations.get(_relationsName).has(_dataItem.parentCode)) {
              this._relations.get(_relationsName).set(_dataItem.parentCode, /* @__PURE__ */ new Set());
            }
            this._relations.get(_relationsName).get(_dataItem.parentCode).add({
              id: _dataItem.code,
              dataType: _dataType
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
  /**
   * 获取某个配置列表
   * @param dataType
   */
  getDatas(dataType) {
    return this._data.get(dataType);
  }
  /**
   * 获取某个选中配置
   * @param dataType
   * @param id
   */
  getData(dataType, id) {
    return this._data.get(dataType).get(id);
  }
  /**
   * 获取与选中配置相关的配置列表
   * @param dataType
   * @param id
   */
  getRelationDatas(dataType, id) {
    const _that = this;
    return new Set(
      function* () {
        if (!_that._relations.get(dataType).has(id)) {
          return;
        }
        for (const _relationItem of _that._relations.get(dataType).get(id)) {
          yield _that.getData(_relationItem.dataType, _relationItem.id);
        }
      }()
    );
  }
}
export class OrderService {
  //计算价格
  computePrice(requestDto) {
    return __async(this, null, function* () {
      return yield buyProductAPI.computePrice(requestDto);
    });
  }
  //生成新购订单
  buildOrder(requestDto) {
    return __async(this, null, function* () {
      return yield buyProductAPI.buildOrder(requestDto);
    });
  }
}
