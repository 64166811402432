"use strict";
export const moneyMock = (mock) => {
  mock.onGet("/api/v1/products").reply((config) => {
    let _response = {
      "model": "product_model",
      "number_region_list": [
        "\u4E2D\u56FD\u968F\u673A",
        "\u4E09\u95E8\u5CE1",
        "\u4E0A\u6D77",
        "\u5E7F\u5DDE",
        "\u821F\u5C71",
        "\u8862\u5DDE",
        "\u90D1\u5DDE",
        "\u91CD\u5E86"
      ],
      "number_type_list": [
        {
          "model": "number_type_model",
          "guid": "5",
          "number_type": "\u865A\u62DF",
          "purchase_time_list": [
            {
              "model": "purchase_time_model",
              "guid": "7",
              "purchase_time": "3\u4E2A\u6708",
              "discount": null
            },
            {
              "model": "purchase_time_model",
              "guid": "8",
              "purchase_time": "\u534A\u5E74",
              "discount": "9.5\u6298"
            },
            {
              "model": "purchase_time_model",
              "guid": "9",
              "purchase_time": "\u4E00\u5E74",
              "discount": "9\u6298"
            }
          ]
        },
        {
          "model": "number_type_model",
          "guid": "6",
          "number_type": "\u8FD0\u8425\u5546",
          "purchase_time_list": [
            {
              "model": "purchase_time_model",
              "guid": "10",
              "purchase_time": "3\u4E2A\u6708",
              "discount": null
            },
            {
              "model": "purchase_time_model",
              "guid": "11",
              "purchase_time": "\u534A\u5E74",
              "discount": "9.5\u6298"
            },
            {
              "model": "purchase_time_model",
              "guid": "12",
              "purchase_time": "\u4E00\u5E74",
              "discount": "9\u6298"
            }
          ]
        }
      ],
      "coin": 10
    };
    return [200, _response];
  });
};
