"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Mixins } from "vue-property-decorator";
import LangSelect from "@/components/LangSelect/index.vue";
import API from "@ym-framework/api-utils";
import { findParent, Menus } from "@/configuration/constant/menus";
import Tips from "./tips/Tips.vue";
import { MenuService } from "@/service/menu.service";
import {
  NotificationDialogBeforeKey,
  NotificationDialogKey
} from "@/configuration/constant/base";
import { AccountSessionMixins } from "@/utils/account-session";
let Layout = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.logo = require("@/assets/style/images/logo.png");
    this.isCollapse = false;
    // 菜单配置
    this.menus = Menus;
    // 激活菜单
    this.activeMenu = "";
    this.screenWidth = document.body.clientWidth;
    // 屏幕宽度
    this.timer = false;
    this.titleNum = {
      is_pased_number: 0,
      //即将过期号码数量
      is_entities_number: 0,
      //未绑定实体号数量
      is_unsubscribe_number: 0,
      //号码已退订数量
      is_all_number: 0,
      //号码总数量
      bind_emails_number: 0,
      // 已绑定邮箱
      unbind_emails_number: 0,
      // 未绑定邮箱
      bind_entities_number: 0
      // 已绑定实体号
    };
  }
  mounted() {
    return __async(this, null, function* () {
      this.getActiveMenu();
    });
  }
  // 注销
  toLogout() {
    this.$router.push("/logout");
  }
  // 获取菜单配置
  get availableMenus() {
    return this.menus.filter((item) => {
      if (item.powers) {
        const powerResult = item.powers.map((power) => this.checkPower(power)).every((result) => result);
        if (!powerResult) {
          return false;
        }
        if (item.children) {
          item.children = item.children.filter((item2) => {
            let powerItemResult = true;
            if (item2.powers) {
              powerItemResult = item2.powers.map((power) => this.checkPower(power)).every((result) => result);
            }
            if (!powerItemResult) {
              return false;
            } else {
              return item2;
            }
          });
        }
        return item;
      }
    });
  }
  // 用于显示不用类型云号数量 【全部】 【未绑定实体号】 【号码已退订】 【即将过期】
  getPhoneNum() {
    const defaultMenu = this.availableMenus[0];
    defaultMenu.children.forEach((item, index) => {
      var _a, _b, _c, _d, _e, _f, _g;
      if (item.id === "yh-all") {
        defaultMenu.children[index].phoneNumber = String(
          (_a = this.titleNum.is_all_number) != null ? _a : 0
        );
      }
      if (item.id === "yh-expire") {
        defaultMenu.children[index].phoneNumber = String(
          (_b = this.titleNum.is_pased_number) != null ? _b : 0
        );
      }
      if (item.id === "yh-unbound") {
        defaultMenu.children[index].phoneNumber = String(
          (_c = this.titleNum.is_entities_number) != null ? _c : 0
        );
      }
      if (item.id === "yh-unsubscribe") {
        defaultMenu.children[index].phoneNumber = String(
          (_d = this.titleNum.is_unsubscribe_number) != null ? _d : 0
        );
      }
      if (item.id === "yh-bindemail") {
        defaultMenu.children[index].phoneNumber = String(
          (_e = this.titleNum.bind_emails_number) != null ? _e : 0
        );
      }
      if (item.id === "yh-unbindemail") {
        defaultMenu.children[index].phoneNumber = String(
          (_f = this.titleNum.unbind_emails_number) != null ? _f : 0
        );
      }
      if (item.id === "yh-bound") {
        defaultMenu.children[index].phoneNumber = String(
          (_g = this.titleNum.bind_entities_number) != null ? _g : 0
        );
      }
    });
    if (defaultMenu.id === "main") {
      this.availableMenus.splice(0, 1, defaultMenu);
    }
  }
  // 获取云号数量
  getTitleNum() {
    return __async(this, null, function* () {
      var _a, _b;
      try {
        let response = yield API.get(
          `api://base/api/v1/phone/number/all`
        );
        response.data.result.is_unsubscribe_number;
        this.titleNum.is_pased_number = response.data.result.is_pased_number;
        this.titleNum.is_entities_number = response.data.result.is_entities_number;
        this.titleNum.is_all_number = response.data.result.is_all_number;
        this.titleNum.bind_emails_number = response.data.result.bind_emails_number;
        this.titleNum.bind_entities_number = response.data.result.bind_entities_number;
        this.titleNum.unbind_emails_number = response.data.result.unbind_emails_number;
        this.titleNum.is_unsubscribe_number = response.data.result.is_unsubscribe_number;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        if (((_b = (_a = error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.errors.datas[0].code) === "check.certification.error") {
          return;
        }
      }
    });
  }
  // 展开收起
  expand() {
    this.isCollapse = !this.isCollapse;
    localStorage.setItem("expandStatus", this.isCollapse + "");
  }
  getExpandStatus() {
    this.isCollapse = localStorage.getItem("expandStatus") === "true";
  }
  // 获取当前激活菜单
  getActiveMenu() {
    var _a, _b;
    const menuService = new MenuService();
    this.activeMenu = (_b = (_a = menuService.compatibleWithTag(this.$route.query.tag)) != null ? _a : localStorage.getItem("activeMenu")) != null ? _b : "main";
    this.setActiveMenu(this.activeMenu);
  }
  // 设置图标激活
  setActiveMenu(key) {
    this.menus.map((item) => item.active = false);
    const parent = findParent(key, this.availableMenus);
    if (parent) {
      parent.active = true;
    }
    this.menus.filter((item) => item.id === key && (item.active = true));
  }
  // 点击菜单
  handleSelect(key) {
    this.activeMenu = key;
    localStorage.setItem("activeMenu", key);
    this.setActiveMenu(key);
    this.$emit("activeMenu", key);
    this.$router.push(key);
  }
  // 获取弹窗提示
  getTip() {
    if (localStorage.getItem(NotificationDialogBeforeKey)) {
      localStorage.removeItem(NotificationDialogBeforeKey);
    }
    const currentTime = +new Date();
    const tipSavedTime = localStorage.getItem(NotificationDialogKey);
    let isOverTime = false;
    if (tipSavedTime) {
      isOverTime = (currentTime - +tipSavedTime) / (1e3 * 60 * 60 * 24) > 3;
    }
  }
  Recharge() {
    this.activeMenu = "recharge";
    localStorage.setItem("activeMenu", "recharge");
    this.setActiveMenu("recharge");
    this.$emit("activeMenu", "recharge");
    this.$router.push("/recharge");
  }
};
Layout = __decorateClass([
  Component({
    components: {
      LangSelect,
      Tips
    }
  })
], Layout);
export {
  Layout as default
};
