"use strict";
export const OrderType = [
  {
    status: "WAIT",
    name: "\u7B49\u5F85\u652F\u4ED8"
  },
  {
    status: "FINISH",
    name: "\u5DF2\u5B8C\u6210"
  },
  {
    status: "ALLOCATION",
    name: "\u5F85\u653E\u53F7"
  },
  {
    status: "CANCEL",
    name: "\u53D6\u6D88"
  },
  {
    status: "PAY",
    name: "\u5DF2\u652F\u4ED8"
  }
];
