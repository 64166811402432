"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, PropSync, Vue, Ref } from "vue-property-decorator";
export class UiDialogController {
  constructor() {
    this._visible = false;
  }
  set visible(visible) {
    this._visible = visible;
  }
  get visible() {
    return this._visible;
  }
}
let UiDialog = class extends Vue {
  show() {
    this._controller.visible = true;
  }
  hide() {
    this._controller.visible = false;
  }
};
__decorateClass([
  Ref("_dialog-element")
], UiDialog.prototype, "_form", 2);
__decorateClass([
  PropSync("controller", {
    type: UiDialogController,
    default: () => {
      return new UiDialogController();
    }
  })
], UiDialog.prototype, "_controller", 2);
UiDialog = __decorateClass([
  Component
], UiDialog);
export {
  UiDialog as default
};
