"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let shopsListModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.shop_id = "";
    //店铺序列号
    this.shop_name = "";
  }
  //店铺名称
};
shopsListModel.model = "shops.list";
shopsListModel = __decorateClass([
  Annotation.ModelMeta
], shopsListModel);
export let phonesListModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.id = "1404737565059321856";
    this.phone = "17003255813";
    this.num = 0;
    this.bind_status = "";
  }
};
phonesListModel.model = "phone.num.model";
phonesListModel = __decorateClass([
  Annotation.ModelMeta
], phonesListModel);
export let selectPhoneModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.phone_list = [];
  }
};
selectPhoneModel.model = "phone.list.model";
selectPhoneModel = __decorateClass([
  Annotation.ModelMeta
], selectPhoneModel);
export let eventSelectPhoneModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.id = "";
    this.phone = "";
  }
};
eventSelectPhoneModel.model = "phone.model";
eventSelectPhoneModel = __decorateClass([
  Annotation.ModelMeta
], eventSelectPhoneModel);
export let selectListModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.datas = [];
  }
};
selectListModel.model = "selectS.list.model";
selectListModel = __decorateClass([
  Annotation.ModelMeta
], selectListModel);
export let eventselectSonModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.name = "\u5458\u5DE524";
    this.guid = "user_cad491357ec54d70998ec4cb39871855";
    this.account = "158465617460024";
  }
};
eventselectSonModel.model = "select.model";
eventselectSonModel = __decorateClass([
  Annotation.ModelMeta
], eventselectSonModel);
