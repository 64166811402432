"use strict";
export const cloudBatch = [
  {
    name: "\u5F00\u542F\u5F55\u97F3",
    power: "SWITCH_RECORDING",
    id: "yh-open-audio"
  },
  {
    name: "\u5173\u95ED\u5F55\u97F3",
    power: "SWITCH_RECORDING",
    id: "yh-close-audio"
  },
  {
    name: "\u5F00\u542F\u81EA\u52A8\u7EED\u8D39",
    power: "SWITCH_AUTO_RENEW",
    id: "yh-openAutoRenew"
  },
  {
    name: "\u5173\u95ED\u81EA\u52A8\u7EED\u8D39",
    power: "SWITCH_AUTO_RENEW",
    id: "yh-closeAutoRenew"
  },
  {
    name: "\u7ED1\u5B9A\u5B9E\u4F53\u53F7",
    power: "BIND_PHONE",
    id: "yh-binding-number"
  },
  {
    name: "\u89E3\u7ED1\u5B9E\u4F53\u53F7",
    power: "BIND_PHONE",
    id: "yh-unbinding-number"
  },
  {
    name: "\u7ED1\u5B9A\u90AE\u7BB1",
    power: "BIND_EMAIL",
    id: "yh-binding-email"
  },
  {
    name: "\u89E3\u7ED1\u90AE\u7BB1",
    power: "BIND_EMAIL",
    id: "yh-unbinding-email"
  },
  {
    name: "\u8F6C\u79FB\u53F7\u7801",
    power: "TRANSFER_PHONE",
    id: "yh-jump-number"
  },
  {
    name: "\u5BFC\u51FA\u4E91\u53F7\u7801",
    power: "EXPORT_PHONE",
    id: "yh-export-cloudNumber"
  },
  {
    name: "\u6279\u91CF\u7EED\u8D39",
    power: "RENEW_PHONE",
    id: "yh-batch-renew"
  }
];
export const batchDeleteUnsubscribe = [
  {
    name: "\u5220\u9664\u5DF2\u9000\u8BA2\u4E91\u53F7",
    power: "DELETE_PHONE",
    id: "yh-delete-unsubscribe"
  }
];
export const cloudRecordBatch = [
  {
    name: "\u6279\u91CF\u5BFC\u51FA",
    power: "",
    // 无权限
    id: "yh-export-batch"
  }
];
export const teamBatch = [
  {
    name: "\u914D\u7F6E\u6743\u9650",
    power: "ASSIGN_PERMISSIONS",
    id: "yh-config-power"
  }
];
