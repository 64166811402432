"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Mixins } from "vue-property-decorator";
import UiGrid from "@/components/grid/UiGrid.vue";
import moneyVerifyDialog from "../money/money_verify.dialog.vue";
import Back from "@/components/back/Back.vue";
import { AccountSessionMixins } from "@/utils/account-session";
import { RenewalService } from "./service/renewPayment.service";
import { AccountManager } from "@/utils/service/accountManager.service";
import { Debounce } from "vue-debounce-decorator";
import { handleDate } from "@/tools/date";
let ToRenew = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.titleTip = "\u7EED\u8D39\u4E91\u53F7";
    this.loading = false;
    this.paymentAgree = false;
    // userStatus = localStorage.getItem('userStatus')
    this.userFrozenReason = localStorage.getItem("userFrozenReason");
    this.renewService = new RenewalService();
    //列表数据
    this.renewList = {
      number_list: [],
      //云号信息列表
      purchase_time_list: ["1\u4E2A\u6708", "2\u4E2A\u6708", "3\u4E2A\u6708"],
      //购买时长
      pay_modeArr: [
        {
          id: "COIN",
          address: require("@/assets/style/images/pay_jd.png")
        }
        // {
        //   id: "alipay",
        //   address: require("@/assets/style/images/pay_zfb.png")
      ]
      //支付方式
    };
    this.renewViewValue = {
      number_info: "",
      //号码详情
      coin_num: 0,
      //金豆
      cloud_phone_ids: [],
      //续费号码id
      purchase_time: this.renewList.purchase_time_list[0],
      //购买时长
      is_voluntarily: true,
      //是否自动续费
      pay_mode: "coin",
      //支付方式
      product_price: 0,
      //现价
      original_price: 0,
      //原价
      is_agree: false
      //是否同意协议,
    };
  }
  mounted() {
    return __async(this, null, function* () {
      this.loading = true;
      yield this.getNumberData();
      yield this.getCoin();
      yield this.computedPrice();
      this.loading = false;
    });
  }
  //获取云号信息
  getNumberData() {
    return __async(this, null, function* () {
      try {
        if (this.$route.query.guid) {
          let arr = JSON.parse(this.$route.query.guid);
          yield this.renewService.getRenewProductList(arr);
        } else {
          this.$router.push("/main");
        }
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  //获取金豆
  getCoin() {
    return __async(this, null, function* () {
      try {
        const accountService = new AccountManager();
        yield accountService.session();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date);
  }
  computedPrice() {
    return __async(this, null, function* () {
      yield this.renewService.computePrice();
    });
  }
  //查看协议
  clause() {
    window.open("/Terms");
  }
  //后退
  goback() {
    this.$router.go(-1);
  }
  //打开收费详情
  getChargeRule() {
  }
  //改变支付方式
  changePayMode(payMode) {
    this.renewService.renewConfig.payPlatform = payMode;
  }
  //提交订单
  submit() {
    return __async(this, null, function* () {
      if (!this.paymentAgree) {
        this.$alert("\u8BF7\u540C\u610F\u670D\u52A1\u6761\u6B3E", "\u63D0\u793A", {
          confirmButtonText: "\u786E\u5B9A"
        });
        return;
      }
      try {
        this.loading = true;
        let response = yield this.renewService.renewNumbers();
        this._moneyVerifyDialog.open(response);
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.loading = false;
      }
    });
  }
  //去充值
  Recharge() {
    window.open("https://www.yunhao.shop/cost?name=cloudNumber");
  }
};
__decorateClass([
  Ref("money-verify-dialog")
], ToRenew.prototype, "_moneyVerifyDialog", 2);
__decorateClass([
  Debounce(500)
], ToRenew.prototype, "computedPrice", 1);
ToRenew = __decorateClass([
  Component({
    components: {
      UiGrid,
      moneyVerifyDialog,
      Back
    }
  })
], ToRenew);
export {
  ToRenew as default
};
