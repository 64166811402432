"use strict";
export const cloudCallLogMock = (mock) => {
  mock.onGet("/api/v1/cloud/calls").reply((config) => {
    let _response = {};
    _response = {
      "model": "grid.result",
      "result": {
        "model": "data.set",
        "datas": [
          {
            "model": "call.cloud",
            "guid": "789456",
            //实体号码ID
            "shop_guid": "456789",
            //店铺ID
            "shop_name": "\u5E97\u94FA\u540D\u79F0",
            //店铺名称
            "cloud_phone_id": "123456",
            //云号码ID
            "cloud_phone": "15234685176",
            //云号码
            "entity_phone": "13333333333",
            //绑定实体号
            "calling_number": "15863541254",
            //主叫号码
            "called_number": "13645684512",
            //被叫号码
            "call_type": 0,
            //类型
            "call_type_name": 0,
            //类型名称
            "call_time": 16224768e5,
            //通话时间
            "call_duration": "00:12:45",
            //时长
            "called_address": "",
            //录音文件地址
            "called_file": 0,
            //是否过期
            "remark": "\u6211\u662F\u5907\u6CE81"
            //备注
          },
          {
            "model": "call.cloud",
            "guid": "789456",
            //实体号码ID
            "shop_guid": "456789",
            //店铺ID
            "shop_name": "\u5E97\u94FA\u540D\u79F0",
            //店铺名称
            "cloud_phone_id": "123456",
            //云号码ID
            "cloud_phone": "15234685176",
            //云号码
            "entity_phone": "13333333333",
            //绑定实体号
            "calling_number": "15863541254",
            //主叫号码
            "called_number": "13645684512",
            //被叫号码
            "call_type": 0,
            //类型
            "call_type_name": 0,
            //类型名称
            "call_time": 16224768e5,
            //通话时间
            "call_duration": "00:12:45",
            //时长
            "called_address": "",
            //录音文件地址
            "called_file": 0,
            //是否过期
            "remark": "\u6211\u662F\u5907\u6CE82"
            //备注
          },
          {
            "model": "call.cloud",
            "guid": "789456",
            //实体号码ID
            "shop_guid": "456789",
            //店铺ID
            "shop_name": "\u5E97\u94FA\u540D\u79F0",
            //店铺名称
            "cloud_phone_id": "123456",
            //云号码ID
            "cloud_phone": "15234685176",
            //云号码
            "entity_phone": "13333333333",
            //绑定实体号
            "calling_number": "15863541254",
            //主叫号码
            "called_number": "13645684512",
            //被叫号码
            "call_type": 0,
            //类型
            "call_type_name": 0,
            //类型名称
            "call_time": 16224768e5,
            //通话时间
            "call_duration": "00:12:45",
            //时长
            "called_address": "",
            //录音文件地址
            "called_file": 0,
            //是否过期
            "remark": "\u6211\u662F\u5907\u6CE83"
            //备注
          }
        ],
        "total": 6
      },
      "page": {
        "model": "page",
        "current": 1,
        "total": 1,
        "size": 10
      }
    };
    return [200, _response];
  });
};
