"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
let PaymentConfirm = class extends Vue {
  constructor() {
    super(...arguments);
    this.paymentStatus = "";
    this.payment_failed_reason = "";
    this.jump_type = false;
    this.payment_time_last = 5;
    this.dialogVisible = false;
  }
  //是否显示弹窗
  mounted() {
    return __async(this, null, function* () {
      var _a;
      let status = this.$route.params.type;
      let coin = (_a = this.$route.params) == null ? void 0 : _a.coin;
      if (!status) {
        this.$router.push({ path: "/main", replace: true });
        return false;
      }
      if (status === "success") {
        this.paymentStatus = "success";
        if (coin !== null && coin !== void 0 && +coin < 5) {
          this.openDialog();
          return false;
        }
        this.timer_last();
      }
      if (status === "failed") {
        this.paymentStatus = "failed";
        this.payment_failed_reason = this.$route.params.reason;
      }
    });
  }
  //关闭前的回调
  beforeCloseDialog(done) {
    this.dialogVisible = false;
    this.timer_last();
    done();
  }
  //关闭弹窗
  closeDialog() {
    this.dialogVisible = false;
    this.timer_last();
  }
  //打开弹窗
  openDialog() {
    this.dialogVisible = true;
  }
  confirm() {
    this.closeDialog();
  }
  //充值
  Recharge() {
    window.open("https://www.yunhao.shop/cost?name=cloudNumber");
  }
  jump() {
    this.jump_type = true;
    this.$router.push({ path: "/main", replace: true });
  }
  gopay() {
    this.$router.push({ path: "/main", replace: true });
  }
  timer_last() {
    let timer_last = setInterval(() => {
      if (this.payment_time_last > 0) {
        this.payment_time_last = this.payment_time_last - 1;
      }
      if (this.payment_time_last <= 0) {
        clearInterval(timer_last);
        if (!this.jump_type) {
          this.$router.push({ path: "/main", replace: true });
        }
        this.payment_time_last = 5;
      }
    }, 1e3);
  }
};
PaymentConfirm = __decorateClass([
  Component
], PaymentConfirm);
export {
  PaymentConfirm as default
};
