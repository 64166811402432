"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import UiDialog from "@/components/dialog/UiDialog.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import DistributionDialog from "@/components/dialog/DistributionDialog.vue";
import { APIDataSource } from "@/components/grid/data-source";
import Question from "@/components/Question.vue";
import _ from "lodash";
let linkStore = class extends Vue {
  constructor() {
    super(...arguments);
    this.title = "";
    this.activeName = "first";
    this.bthLoading = false;
    this.gridController = new GridController(
      new APIDataSource("api://base/api/v1/appmarket/personal/users")
    );
    this.userId = "";
    this.selectLock = false;
    this.selectSonArr = [];
    this.params = {
      search: ""
      //搜索字符串
    };
    this.cloud_phone_guid = "";
    this.searchEvent = _.debounce((searchStr) => {
      if (searchStr !== null && searchStr !== "") {
        this.gridController.query.filter = `name=='*${searchStr}*'`;
      }
      if (searchStr === null || searchStr === "") {
        this.gridController.query.filter = null;
      }
      this.gridController.query.current = 1;
      this.gridController.search();
    }, 800);
  }
  created() {
    this.gridController.on("searched", (item) => {
      this.checkItem();
    });
  }
  init() {
    return __async(this, null, function* () {
      this.selectSonArr = [];
    });
  }
  open() {
    return __async(this, null, function* () {
      this.params.search = "";
      this.selectLock = false;
      this.init();
      this._distributionDialog.open();
      yield this.gridController.search();
      yield this.selectSon();
      this.$nextTick(() => {
        for (const item of this.selectSonArr) {
          this._distributionDialog.add({
            id: item.guid,
            label: item.name
          });
        }
        this.checkItem();
      });
    });
  }
  selectSon() {
    return __async(this, null, function* () {
      try {
        let response = yield API.get(
          `api://base/api/v1/appmarket/personal/users/authorized`
        );
        this.selectSonArr = response.data.datas;
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      }
    });
  }
  close() {
    this._distributionDialog.close();
    this.$emit("refresh");
  }
  //   收集器变化触发
  changed(data) {
    let rows = _.differenceWith(
      this.gridController.result.datas,
      data,
      (CheckedArr, data2) => CheckedArr.guid == data2.id
    );
    this.selectLock = true;
    if (rows) {
      for (const row of rows) {
        this._multipleTable.toggleRowSelection(row, false);
      }
    }
    this.selectLock = false;
  }
  //   店铺选中
  selectItems(val) {
    if (!this.selectLock) {
      this._distributionDialog.syncData(
        this.gridController.result.datas,
        val,
        "guid",
        "name"
      );
    }
  }
  checkItem() {
    return __async(this, null, function* () {
      let selectDatas = this._distributionDialog.getAll();
      let rows = _.intersectionWith(
        this.gridController.result.datas,
        selectDatas,
        (datas, selectDatas2) => datas.guid === selectDatas2.id
      );
      this.selectLock = true;
      if (rows.length !== 0) {
        for (const row of rows) {
          this._multipleTable.toggleRowSelection(row, true);
        }
      }
      this.selectLock = false;
    });
  }
  rowclick(row, column, event) {
  }
  //   左侧选中列
  selectable(row, index) {
    return true;
  }
  saved() {
    return __async(this, null, function* () {
      this.bthLoading = true;
      try {
        let selectdata = yield this._distributionDialog.getAll();
        let arr = [];
        selectdata.forEach((v) => {
          arr.push(v.id);
        });
        yield API.post(`api://base/api/v1/appmarket/personal/apps/authorize`, {
          ids: arr
        });
        this.$message({
          message: "\u64CD\u4F5C\u6210\u529F",
          type: "success"
        });
        this.close();
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      } finally {
        this.bthLoading = false;
      }
    });
  }
};
__decorateClass([
  Ref("distributionDialog")
], linkStore.prototype, "_distributionDialog", 2);
__decorateClass([
  Ref("multipleTable")
], linkStore.prototype, "_multipleTable", 2);
linkStore = __decorateClass([
  Component({
    components: {
      UiDialog,
      DistributionDialog,
      UiGrid,
      Question
    }
  })
], linkStore);
export {
  linkStore as default
};
