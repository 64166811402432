"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
import { AccountSessionStorage } from "../account-session";
import Cookie from "js-cookie";
export class AccountManager {
  session() {
    return __async(this, null, function* () {
      try {
        const response = yield API.get(`api://base.cloud.auth/v1/sessions`);
        AccountSessionStorage.setSession(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    });
  }
  checkToken() {
    return __async(this, null, function* () {
      try {
        const token = Cookie.get("cloudNumberToken");
        if (token && token !== "") {
          yield API.head(`api://base.cloud.auth/v1/token`);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    });
  }
}
