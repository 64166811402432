"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
import UiForm, { UiFormController } from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import { NumberPoolList } from "@/models/constant/numberPool";
export class HandleFilter extends UiFormController {
}
let filter_dialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.HandleFilter = new HandleFilter();
    this.filter_dialog = new UiDialogController();
    this.showDrawer = false;
    this.filterParams = {
      phone_type: "all",
      //类型  （虚拟号：virtual, 运营商：real,全部：all）
      order_number: "",
      //购买订单号
      is_auto_renewal: 0,
      //是否自动续费 0全部1自动续费2非自动续费
      is_entity: 0,
      //绑定实体号类型 0全部1绑定2未绑定
      phone_status: "",
      //云号码状态下拉框
      number_pool: null
    };
    this.smsFilterParams = {
      search: "",
      // message_type: 0, //类型 0全部1发送2接收
      message_state: 0,
      //状态 0全部2失败1成功
      timer: []
    };
    this.callFilterParams = {
      search: "",
      //搜索字符串
      // call_type: 0, //类型 0全部1接听2拨打
      timer: []
    };
    this.title = "\u7B5B\u9009";
    this.diff = "";
    this.flag = "";
    this.numberPool = [
      {
        name: "\u5168\u90E8",
        code: null
      },
      ...NumberPoolList
    ];
    this.numberState = [
      {
        value: "",
        label: "\u5168\u90E8"
      },
      {
        value: "normal",
        label: "\u6B63\u5E38"
      },
      {
        value: "arrearage",
        label: "\u6B20\u8D39\u4FDD\u53F7"
      }
    ];
  }
  init() {
    this.filterParams = {
      phone_type: "all",
      //类型 （虚拟号：virtual, 运营商：real）
      order_number: "",
      //购买订单号
      is_auto_renewal: 0,
      //是否自动续费 0全部1自动续费2非自动续费
      is_entity: 0,
      //绑定实体号状态 0全部1绑定2未绑定
      phone_status: "",
      //云号码状态
      number_pool: null
    };
    this.smsFilterParams = {
      search: "",
      // message_type: 0, //类型 0全部1发送2接收
      message_state: 0,
      //状态 0全部2失败1成功
      timer: []
    };
    this.callFilterParams = {
      search: "",
      //搜索字符串
      // call_type: 0, //类型 0全部1接听2拨打
      timer: []
    };
  }
  open(event) {
    this.showDrawer = true;
  }
  clearDrawer() {
    return __async(this, null, function* () {
      yield this.init();
      yield this.submit();
    });
  }
  close() {
    this.filter_dialog.visible = false;
    if (this.diff === "\u4E91\u53F7\u7801") {
      this.$emit("ListFilter", this.filterParams);
    }
    if (this.diff === "\u77ED\u4FE1\u7B5B\u9009") {
      this.$emit("ListFilter", this.smsFilterParams);
    }
    if (this.diff === "\u901A\u8BDD\u7B5B\u9009") {
      this.$emit("ListFilter", this.callFilterParams);
    }
  }
  submit() {
    return __async(this, null, function* () {
      this.close();
    });
  }
};
filter_dialog = __decorateClass([
  Component({
    components: {
      UiDialog,
      UiForm,
      UiSubmit
    }
  })
], filter_dialog);
export {
  filter_dialog as default
};
