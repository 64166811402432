"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let numberListAll = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.guid = "";
    //云号码ID
    this.cloud_phone = "";
    //云号码
    this.cloud_config = "";
    //号码配置
    this.entity_phone = "";
    //关联主号码
    this.is_recording_enabled = 1;
    //录音开启或关闭 1开启2关闭
    this.use_date = 0;
    //可用天数
    this.cloud_expire_time = 0;
    //云号到期时间
    this.cloud_status = "";
    //状态  normal：正常，unsubscribe：退订，arrearage：欠费保号
    this.cloud_status_name = "";
    //状态名称  正常、已退订、欠费保号
    this.is_auto_renewal = 0;
    //是否自动续费
    this.temporary_called = "";
    //临时被叫号码
    this.temporary_expire_time = 0;
    //临时被叫到期时间
    this.shop_guid = "";
    //关联店铺ID
    this.shop_guid_name = "";
    //关联店铺名称，全部返回
    this.remark = "";
    //备注
    this.start_time = 0;
    //启用时间
    this.is_use = "";
    //是否启用
    this.number_pool = 1;
    //号码池
    this.user_guid = "";
    //所属主账号用户ID
    this.forward_email = "";
  }
  // 绑定邮箱
};
numberListAll.model = "phone.cloud";
numberListAll = __decorateClass([
  Annotation.ModelMeta
], numberListAll);
export let TitleNumAllModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.messages = "success";
    this.result = new TitleNumAll_resultModel();
  }
};
TitleNumAllModel.model = "operation";
TitleNumAllModel = __decorateClass([
  Annotation.ModelMeta
], TitleNumAllModel);
export let TitleNumAll_resultModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.is_pased_number = 0;
    //即将过期号码数量
    this.is_entities_number = 0;
    //未绑定实体号数量
    this.is_unsubscribe_number = 0;
    //已退订
    this.is_all_number = 0;
    //全部
    this.bind_emails_number = 0;
    // 已绑定邮箱
    this.unbind_emails_number = 0;
    // 未绑定邮箱
    this.bind_entities_number = 0;
  }
  // 已绑定实体号
};
TitleNumAll_resultModel.model = "phone.number.all";
TitleNumAll_resultModel = __decorateClass([
  Annotation.ModelMeta
], TitleNumAll_resultModel);
