"use strict";
export const cloudInfoLog = (mock) => {
  mock.onPost("/api/v1/product/calculate/buy").reply((config) => {
    let _response = {};
    _response = {
      "model": "calculate_buy_model",
      "calculate_req": {
        "model": "",
        "number_region": "number_region",
        "number_type": "number_type",
        "purchase_time": "7",
        "num": 3,
        "pay_mode": "coin",
        "discount_code": "discount_code"
      },
      "product_price": 45,
      "original_price": 60
    };
    return [200, _response];
  });
  mock.onPost("/api/v1/order/buy").reply((config) => {
    let _response = {};
    _response = {
      "model": "order.create",
      "message": "success",
      "order_guid": "1654895164985162"
      //订单ID
    };
    return [200, _response];
  });
};
