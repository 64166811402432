"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Mixins } from "vue-property-decorator";
import { AccountSessionMixins } from "@/utils/account-session";
let Tips = class extends Mixins(AccountSessionMixins) {
  mounted() {
  }
};
Tips = __decorateClass([
  Component({
    components: {}
  })
], Tips);
export {
  Tips as default
};
