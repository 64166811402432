"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, PropSync, Vue } from "vue-property-decorator";
let Back = class extends Vue {
  back() {
    if (!this.routeSync) {
      this.$router.go(-1);
    } else {
      this.$router.push(this.routeSync);
    }
  }
};
__decorateClass([
  PropSync("title")
], Back.prototype, "titleSync", 2);
__decorateClass([
  PropSync("route")
], Back.prototype, "routeSync", 2);
Back = __decorateClass([
  Component({
    components: {}
  })
], Back);
export {
  Back as default
};
