"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let smsDetailModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.messages = "success";
    this.result = new smsDetailResultModel();
  }
};
smsDetailModel.model = "operation";
smsDetailModel = __decorateClass([
  Annotation.ModelMeta
], smsDetailModel);
export let ValidModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.result = "success";
    this.resourceToken = "";
  }
};
ValidModel.model = "password.valid";
ValidModel = __decorateClass([
  Annotation.ModelMeta
], ValidModel);
export let smsDetailResultModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.message_pay = 0;
    //费用
    this.message = "";
    //短信
    this.message_length = 0;
  }
  //字数
};
smsDetailResultModel.model = "";
smsDetailResultModel = __decorateClass([
  Annotation.ModelMeta
], smsDetailResultModel);
export let getIsEfficacyModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.messages = "success";
    this.result = new getIsEfficacyResultModel();
  }
};
getIsEfficacyModel.model = "operation";
getIsEfficacyModel = __decorateClass([
  Annotation.ModelMeta
], getIsEfficacyModel);
export let getIsEfficacyResultModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.check_password = true;
  }
  //是否需要验证码 true验证false直接查看
};
getIsEfficacyResultModel.model = "message.detail";
getIsEfficacyResultModel = __decorateClass([
  Annotation.ModelMeta
], getIsEfficacyResultModel);
