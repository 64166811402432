"use strict";
import Vue from "vue";
import "@/configuration";
import App from "./App.vue";
import router from "./router";
import i18n from "@/lang";
import ElementUI from "element-ui";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/style/theme/index.css";
import "./assets/style/main.scss";
import "./assets/style/icon-ali/iconfont.css";
import AudioRecorder from "vue-audio-recorder";
Vue.use(AudioRecorder);
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.config.productionTip = false;
new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
