"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let cloudInfoLogModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.guid = "";
    //记录ID
    this.cloud_phone_id = "";
    //云号码ID
    this.cloud_phone = "";
    //云号码
    this.shop_guid = "";
    //关联店铺
    this.shop_name = "";
    //关联店铺名称
    this.entity_phone = "";
    //绑定实体号
    this.receiver_number = "";
    //接收方号码
    this.sender_number = "";
    //发送方号码
    this.sms_type = 0;
    //短信类型 1发送、2接收
    this.sms_type_name = "";
    //短信类型名称 发送、接收
    this.sms_time = 0;
    //短信时间
    this.sms_status = 0;
    //短信状态 2失败1成功
    this.sms_status_name = "";
    //短信状态名称 失败、成功
    this.remark = "";
  }
  //备注
};
cloudInfoLogModel.model = "info.cloud";
cloudInfoLogModel = __decorateClass([
  Annotation.ModelMeta
], cloudInfoLogModel);
