"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
export class CollectorItem {
  constructor() {
    this.id = "";
    this.label = "";
  }
}
let Collector = class extends Vue {
  constructor() {
    super(...arguments);
    this.collectorActiveName = "collector-first";
    this.searchStr = "";
    this.listData = new Array();
    this.showData = new Array();
    this.changed = _.debounce((listData) => {
      this.$emit("changed", listData);
    }, 200);
  }
  mounted() {
  }
  add(itme) {
    let _item = _.find(this.listData, { id: itme.id });
    if (_item === void 0) {
      this.listData.push(itme);
    }
    this.search();
  }
  del(itme) {
    this.listData = _.filter(this.listData, (data) => {
      return data.id !== itme.id;
    });
    this.search();
  }
  getAll() {
    this.search();
    return this.listData;
  }
  clear() {
    this.searchStr = "";
    this.listData = new Array();
    this.showData = new Array();
    this.search();
  }
  search() {
    this.showData = JSON.parse(JSON.stringify(this.listData));
    if (this.searchStr !== "" && this.searchStr !== null && this.searchStr !== void 0) {
      this.showData = _.filter(this.showData, (data) => {
        return data.label.indexOf(this.searchStr) != -1;
      });
    }
    this.changed(this.listData);
  }
  handleClick() {
  }
  refresh() {
  }
};
Collector = __decorateClass([
  Component({
    components: {}
  })
], Collector);
export {
  Collector as default
};
