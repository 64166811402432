"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Mixins } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import { AccountSessionMixins } from "@/utils/account-session";
import API from "@ym-framework/api-utils";
import { Debounce } from "vue-debounce-decorator";
import VueQr from "vue-qr";
let Recharge = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.rechargeList = [
      {
        id: 50,
        description: "50\u5143"
      },
      {
        id: 100,
        description: "100\u5143"
      },
      {
        id: 200,
        description: "200\u5143"
      },
      {
        id: 500,
        description: "500\u5143"
      }
    ];
    this.loading = false;
    this.payLink = "";
    this.payAmount = 0;
    this.orderId = "";
    this.rechargeAutoAmount = 1;
    this.rechargePaymentMethods = ["ALIPAY"];
    this.dialogVisible = false;
    // 轮询器
    this.timer = null;
    this.rechargeOption = {
      product: 50,
      platform: "ALIPAY"
    };
  }
  mounted() {
  }
  getRechargeList() {
    return __async(this, null, function* () {
      try {
        yield API.get(`api://base.cloud.auth/v1/`);
      } catch (error) {
        if (error.response.data.hasOwnProperty("errors")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  changeCustomize() {
    this.rechargeOption.product = "CUSTOM";
  }
  changePlatform() {
  }
  recharge() {
    return __async(this, null, function* () {
      try {
        this.loading = true;
        yield this.createOrder();
      } catch (error) {
        if (error.response.data.hasOwnProperty("errors")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.loading = false;
      }
    });
  }
  createOrder() {
    return __async(this, null, function* () {
      try {
        let rechargeOrderData = {
          "type": "TOP_UP",
          "payPlatform": "ALIPAY",
          "topUp": {
            "money": this.rechargeOption.product === "CUSTOM" ? this.rechargeAutoAmount : this.rechargeOption.product
          }
        };
        let res = yield API.post(`api://base.cloud.auth/v1/orders`, rechargeOrderData);
        this.payLink = res.data.url;
        this.payAmount = res.data.totalCount;
        this.orderId = res.data.orderNo;
        this.dialogVisible = true;
        this.pollingOrder();
      } catch (error) {
        if (error.response.data.hasOwnProperty("errors")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  closeDialog() {
    this.dialogVisible = false;
    window.location.reload();
  }
  pollingOrder() {
    let maxTime = 300;
    let currentTime = 0;
    this.timer = setInterval(() => {
      currentTime++;
      if (currentTime >= maxTime) {
        clearInterval(this.timer);
      }
      this.getOrder();
    }, 1e3);
  }
  getOrder() {
    return __async(this, null, function* () {
      let res = yield API.get(`api://base.cloud.auth/v1/orders/${this.orderId}`);
      if (res.data.status === "FINISH") {
        clearInterval(this.timer);
        this.closeDialog();
      }
    });
  }
};
__decorateClass([
  Debounce(400)
], Recharge.prototype, "createOrder", 1);
Recharge = __decorateClass([
  Component({
    components: {
      Layout,
      VueQr
    }
  })
], Recharge);
export {
  Recharge as default
};
