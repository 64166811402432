"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let cloudCallLogModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.guid = "";
    //记录ID
    this.shop_guid = "";
    //店铺ID
    this.shop_name = "";
    //店铺名称
    this.cloud_phone_id = "";
    //云号码ID
    this.cloud_phone = "";
    //云号码
    this.entity_phone = "";
    //绑定实体号
    this.calling_number = "";
    //主叫号码
    this.called_number = "";
    //被叫号码
    this.call_type = 0;
    //类型
    this.call_type_name = 0;
    //类型名称
    this.call_time = 0;
    //通话时间
    this.call_duration = "";
    //时长
    this.called_address = "";
    //录音文件地址
    this.called_file = true;
    //是否可下载
    this.remark = "";
  }
  //备注
};
cloudCallLogModel.model = "call.cloud";
cloudCallLogModel = __decorateClass([
  Annotation.ModelMeta
], cloudCallLogModel);
