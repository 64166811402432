"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, PropSync, Vue } from "vue-property-decorator";
let Question = class extends Vue {
};
__decorateClass([
  PropSync("placement", { type: String, default: function() {
    return "top-start";
  } })
], Question.prototype, "_placement", 2);
Question = __decorateClass([
  Component
], Question);
export {
  Question as default
};
