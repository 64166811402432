"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import { DownloadManager } from "../utils/downloadManager";
import API from "@ym-framework/api-utils";
import { Debounce } from "vue-debounce-decorator";
let Certification = class extends Vue {
  constructor() {
    super(...arguments);
    this.pageLoading = true;
    this.certificateData = null;
    this.downloadUtil = new DownloadManager();
    this.showCompanyImageUrl = "";
    // showSecurityCompanyImageUrl:string = "";
    this.dialogVisible = false;
    // dialogSecurityVisible:boolean = false;
    this.downloadLoading = false;
    this.formLoading = false;
    this.amountBtnLoading = false;
    this.fileList = [];
    this.CompanyfileList = [];
    this.inputVerifyNumber = 0;
    this.CompanyUploadFormData = {
      businessLicense: "",
      commitment: "",
      enterpriseName: "",
      bank: "",
      bankAccount: "",
      companyFile: "",
      certFile: ""
    };
    this.CompanyFormRules = {
      enterpriseName: [
        {
          required: true,
          message: "\u516C\u53F8\u540D\u79F0\u4E0D\u53EF\u4E3A\u7A7A",
          trigger: "blur"
        }
      ],
      bank: [
        {
          required: true,
          message: "\u5F00\u6237\u884C\u540D\u79F0\u4E0D\u53EF\u4E3A\u7A7A",
          trigger: "blur"
        }
      ],
      bankAccount: [
        {
          required: true,
          message: "\u94F6\u884C\u8D26\u53F7\u4E0D\u53EF\u4E3A\u7A7A",
          trigger: "blur"
        }
      ]
    };
  }
  mounted() {
    return __async(this, null, function* () {
      this._layout.activeMenu = "certification";
      this._layout.setActiveMenu("certification");
      yield this.checkCertificationStatus();
    });
  }
  showBankAccount(bankAccount) {
    const secretAccount = bankAccount.substring(0, 4) + "*******" + bankAccount.substr(-4);
    return secretAccount;
  }
  checkCertificationStatus() {
    return __async(this, null, function* () {
      try {
        this.pageLoading = true;
        const response = yield API.get(`api://base.cloud.auth/v1/certification`);
        this.certificateData = response.data;
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  handlePictureCardPreview(file) {
    this.showCompanyImageUrl = file.url;
    this.dialogVisible = true;
  }
  avatarRemove(file, filelist) {
    if (filelist.length === 0) {
      this.CompanyUploadFormData.companyFile = null;
    }
  }
  restartCertificate() {
    return __async(this, null, function* () {
      this.certificateData.status = "NOT_CERTIFIED";
    });
  }
  beforeAvatarUpload(uploadFile, uploadFiles) {
    if (uploadFile.raw.type !== "image/jpeg" && uploadFile.raw.type !== "image/png") {
      this.$message.error("\u4E0A\u4F20\u7684\u56FE\u7247\u7C7B\u578B\u53EA\u80FD\u662Fjpg\u3001jpeg\u3001png\u683C\u5F0F!");
      uploadFiles.splice(uploadFiles.indexOf(uploadFile), 1);
      return false;
    } else if (uploadFile.raw.size / 1024 / 1024 > 10) {
      this.$message.error("\u4E0A\u4F20\u7684\u56FE\u7247\u5927\u5C0F\u8D85\u8FC710M!");
      uploadFiles.splice(uploadFiles.indexOf(uploadFile), 1);
      return false;
    }
    if (uploadFiles.length > 0) {
      this.CompanyUploadFormData.companyFile = uploadFile.raw;
    }
    return true;
  }
  securityRemove(file, filelist) {
    if (filelist.length === 0) {
      this.CompanyUploadFormData.certFile = null;
    }
  }
  beforeSecurityAvatarUpload(uploadFile, uploadFiles) {
    if (uploadFile.raw.type !== "application/pdf") {
      this.$message.error("\u4E0A\u4F20\u7684\u6587\u4EF6\u7C7B\u578B\u53EA\u80FD\u662Fpdf\u683C\u5F0F!");
      uploadFiles.splice(uploadFiles.indexOf(uploadFile), 1);
      return false;
    }
    if (uploadFiles.length > 0) {
      this.CompanyUploadFormData.certFile = uploadFile.raw;
    }
    return true;
  }
  downloadCommit() {
    return __async(this, null, function* () {
      try {
        this.downloadLoading = true;
        const response = yield API.get(`api://base.cloud.file/v1/commitment`);
        console.log(response);
        this.downloadUtil.downloadFile(response.data, "text/plain", "\u5B89\u5168\u627F\u8BFA\u4E66.pdf");
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          if (error.response.data.hasOwnProperty("errors")) {
            this.$message.error(error.response.data.errors.datas[0].message);
          } else {
            this.$message.error(error.response.statusText);
          }
        }
      } finally {
        this.downloadLoading = false;
      }
    });
  }
  uploadToCert(companyCertificationForm) {
    return __async(this, null, function* () {
      this.formLoading = true;
      yield this.realUploadToCert(companyCertificationForm);
    });
  }
  realUploadToCert(companyCertificationForm) {
    return __async(this, null, function* () {
      try {
        console.log(companyCertificationForm);
        if (!companyCertificationForm) {
          this.formLoading = false;
          return;
        }
        if (companyCertificationForm.companyFile === "" || companyCertificationForm.companyFile === null) {
          this.$message.error("\u8BF7\u4E0A\u4F20\u8425\u4E1A\u6267\u7167");
          return;
        }
        if (companyCertificationForm.certFile === "" || companyCertificationForm.certFile === null) {
          this.$message.error("\u8BF7\u4E0A\u4F20\u5B89\u5168\u627F\u8BFA\u4E66");
          return;
        }
        yield this._companyCertificationForm.validate();
        let companyForm = new FormData();
        companyForm.append("file", companyCertificationForm.companyFile);
        let companyFileResponse = yield API.post(`api://base.cloud.auth/v1/file/upload`, companyForm);
        let companyUrl = companyFileResponse.data.url;
        let certForm = new FormData();
        certForm.append("file", companyCertificationForm.certFile);
        let certFileResponse = yield API.post(`api://base.cloud.auth/v1/file/upload`, certForm);
        let certUrl = certFileResponse.data.url;
        let uploadData = {
          "commitment": certUrl,
          "businessLicense": companyUrl,
          "enterpriseName": companyCertificationForm.enterpriseName,
          "bank": companyCertificationForm.bank,
          "bankAccount": companyCertificationForm.bankAccount
        };
        yield API.post(`api://base.cloud.auth/v1/certification`, uploadData);
        this.$message.success("\u4F01\u4E1A\u8BA4\u8BC1\u4FE1\u606F\u4E0A\u4F20\u6210\u529F");
        yield this.checkCertificationStatus();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.formLoading = false;
      }
    });
  }
  // 确认打款继续认证
  verifyAmount() {
    return __async(this, null, function* () {
      this.amountBtnLoading = true;
      yield this.realVerifyAmount();
    });
  }
  realVerifyAmount() {
    return __async(this, null, function* () {
      try {
        if (this.inputVerifyNumber) {
          yield API.post(`api://base.cloud.auth/v1/vertification-amount`, {
            "paymentAmount": this.inputVerifyNumber
          });
          this.$message.success("\u6253\u6B3E\u91D1\u989D\u63D0\u4EA4\u6210\u529F\uFF0C\u8BF7\u7B49\u5F85\u540E\u53F0\u6838\u5BF9\u786E\u8BA4");
        }
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.amountBtnLoading = false;
        yield this.checkCertificationStatus();
      }
    });
  }
};
__decorateClass([
  Ref("layout")
], Certification.prototype, "_layout", 2);
__decorateClass([
  Ref("companyCertificationForm")
], Certification.prototype, "_companyCertificationForm", 2);
__decorateClass([
  Debounce(400)
], Certification.prototype, "realUploadToCert", 1);
__decorateClass([
  Debounce(400)
], Certification.prototype, "realVerifyAmount", 1);
Certification = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], Certification);
export {
  Certification as default
};
