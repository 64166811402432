"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Vue, Component } from "vue-property-decorator";
export class AccountSessionStorage {
  static getSession() {
    return this._session;
  }
  static setSession(session) {
    this._session = session;
  }
}
export let AccountSessionMixins = class extends Vue {
  get $session() {
    const session = AccountSessionStorage.getSession();
    if (session) {
      return session;
    }
    return void 0;
  }
  checkPower(power) {
    const session = AccountSessionStorage.getSession();
    const powers = session.powers;
    if (powers.includes(power)) {
      return true;
    } else {
      return false;
    }
  }
};
AccountSessionMixins = __decorateClass([
  Component
], AccountSessionMixins);
