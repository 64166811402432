"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
import { handleDate } from "@/tools/date";
let Main_detail = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialog = new UiDialogController();
    this.data = {};
    this.params = {
      cloud_phone_guid: "",
      //所属云号ID
      is_use: 1,
      //0全部1可用2不可用
      is_show: 1
      //是否全部显示 0分页1全部
    };
  }
  created() {
  }
  open(row) {
    console.log(row);
    this.data = row;
    this.dialog.visible = true;
    this.params.cloud_phone_guid = row.guid;
  }
  close() {
    this.dialog.visible = false;
    this.$emit("closeDialog");
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date);
  }
};
Main_detail = __decorateClass([
  Component({
    components: {
      UiDialog
    }
  })
], Main_detail);
export {
  Main_detail as default
};
