"use strict";
export const cloudInfoLog = (mock) => {
  mock.onGet("/api/v1/cloud/short_messages").reply((config) => {
    let _response = {};
    _response = {
      "model": "grid.result",
      "result": {
        "model": "data.set",
        "datas": [
          {
            "model": "info.cloud",
            "guid": "123456",
            //实体号码ID
            "cloud_phone_id": "789",
            //云号码ID
            "cloud_phone": "15222270365",
            //云号码
            "shop_guid": "789456",
            //关联店铺
            "shop_name": "\u6211\u662F\u5173\u8054\u5E97\u94FA",
            //关联店铺名称
            "entity_phone": "13333333333",
            //绑定实体号
            "receiver_number": "18222271365",
            //接收方号码
            "sender_number": "18633354512",
            //发送方号码
            "sms_type": 1,
            //短信类型 1发送、2接收
            "sms_type_name": "\u53D1\u9001",
            //短信类型名称 发送、接收
            "sms_time": 16224768e5,
            //短信时间
            "sms_status": 2,
            //短信状态 2失败1成功
            "sms_status_name": "\u5931\u8D25",
            //短信状态名称 失败、成功
            "remark": "\u6211\u662F\u5907\u6CE8"
            //备注
          },
          {
            "model": "info.cloud",
            "guid": "456",
            //实体号码ID
            "cloud_phone_id": "789",
            //云号码ID
            "cloud_phone": "15222270365",
            //云号码
            "shop_guid": "789456",
            //关联店铺
            "shop_name": "\u6211\u662F\u5173\u8054\u5E97\u94FA1",
            //关联店铺名称
            "entity_phone": "13333333333",
            //绑定实体号
            "receiver_number": "18222271365",
            //接收方号码
            "sender_number": "18633354512",
            //发送方号码
            "sms_type": 1,
            //短信类型 1发送、2接收
            "sms_type_name": "\u53D1\u9001",
            //短信类型名称 发送、接收
            "sms_time": 16224768e5,
            //短信时间
            "sms_status": 2,
            //短信状态 2失败1成功
            "sms_status_name": "\u5931\u8D25",
            //短信状态名称 失败、成功
            "remark": "\u6211\u662F\u5907\u6CE8"
            //备注
          },
          {
            "model": "info.cloud",
            "guid": "789",
            //实体号码ID
            "cloud_phone_id": "789",
            //云号码ID
            "cloud_phone": "15222270365",
            //云号码
            "shop_guid": "789456",
            //关联店铺
            "shop_name": "\u6211\u662F\u5173\u8054\u5E97\u94FA2",
            //关联店铺名称
            "entity_phone": "13333333333",
            //绑定实体号
            "receiver_number": "18222271365",
            //接收方号码
            "sender_number": "18633354512",
            //发送方号码
            "sms_type": 1,
            //短信类型 1发送、2接收
            "sms_type_name": "\u53D1\u9001",
            //短信类型名称 发送、接收
            "sms_time": 16224768e5,
            //短信时间
            "sms_status": 1,
            //短信状态 2失败1成功
            "sms_status_name": "\u6210\u529F",
            //短信状态名称 失败、成功
            "remark": "\u6211\u662F\u5907\u6CE8"
            //备注
          }
        ],
        "total": 6
      },
      "page": {
        "model": "page",
        "current": 1,
        "total": 1,
        "size": 10
      }
    };
    return [200, _response];
  });
  mock.onGet("/api/v1/cloud/check").reply((config) => {
    let _response = {};
    _response = {
      "model": "operation",
      "messages": "success",
      "result": {
        "model": "message.detail",
        "check_password": true
        //是否需要验证码 true验证false直接查看
      }
    };
    return [200, _response];
  });
  mock.onPost(/\/api\/v1\/cloud\/\w+\/detail/).reply((config) => {
    let _response = {};
    _response = {
      "model": "operation",
      "messages": "success",
      "result": {
        "model": "phone.enetities",
        "message_pay": 0.01,
        //费用
        "message": "\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9\u77ED\u4FE1\u5185\u5BB9",
        //短信
        "message_length": 12
        //字数
      }
    };
    return [200, _response];
  });
};
