"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { EventEmitter } from "events";
import { Component, PropSync, Vue, Ref } from "vue-property-decorator";
import { GeneralGridResult, GeneralSearchQuery } from "./core";
export var GridStatus = /* @__PURE__ */ ((GridStatus2) => {
  GridStatus2["LOADING"] = "loading";
  GridStatus2["COMPLETE"] = "complete";
  return GridStatus2;
})(GridStatus || {});
export class GridController extends EventEmitter {
  constructor(source) {
    super();
    this._status = "complete" /* COMPLETE */;
    this._searchQuery = new GeneralSearchQuery();
    this._source = source;
    this._result = new GeneralGridResult();
  }
  get query() {
    return this._searchQuery;
  }
  get result() {
    return this._result;
  }
  get source() {
    return this._source;
  }
  get status() {
    return this._status;
  }
  set status(status) {
    this._status = status;
  }
  search() {
    return __async(this, null, function* () {
      this.status = "loading" /* LOADING */;
      const _result = yield this.source.search(this.query);
      this.result.clear();
      for (const _item of _result.datas) {
        this.result.add(_item);
      }
      this.result.page.current = _result.page.current;
      this.result.page.total = _result.page.total;
      this.result.page.size = _result.page.size === 0 ? 10 : _result.page.size;
      this.status = "complete" /* COMPLETE */;
      this.emit("searched", this);
    });
  }
}
let UiGrid = class extends Vue {
  constructor() {
    super(...arguments);
    this.nullImg = require("@/assets/style/images/kong-vd.b11ecd8e.png");
  }
  mounted() {
    var _a;
    if (this._autoSearch) {
      (_a = this._controller) == null ? void 0 : _a.search();
    }
  }
  currentChange(current) {
    this._controller.query.current = current;
    this._controller.search();
  }
  sizeChange(size) {
    this._controller.query.size = size;
    this._controller.query.current = 1;
    this._controller.search();
  }
};
__decorateClass([
  Ref("_pagination-element")
], UiGrid.prototype, "_pagination", 2);
__decorateClass([
  PropSync("autoSearch", { type: Boolean, default: true })
], UiGrid.prototype, "_autoSearch", 2);
__decorateClass([
  PropSync("controller", { type: GridController, default: null })
], UiGrid.prototype, "_controller", 2);
UiGrid = __decorateClass([
  Component
], UiGrid);
export {
  UiGrid as default
};
