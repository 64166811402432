"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Mixins } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import Question from "@/components/Question.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { handleDate } from "@/tools/date";
import orderDetail from "./componentsNew/order_detail_dialog.vue";
import { MenuService } from "@/service/menu.service";
import { AccountSessionMixins } from "@/utils/account-session";
const _gridController = new GridController(
  new APIDataSource("api://base.cloud.auth/v1/orders")
);
let order = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.gridController = _gridController;
    this.activeName = "order";
    this.nullImg = require("@/assets/style/images/kong-vd.b11ecd8e.png");
    this.nullText = "\u6682\u65E0\u8BA2\u5355";
    this.muchSelectArr = [];
    this.params = {
      search: "",
      sort: ""
      //排序use_date,asc;cloud_phone,desc
    };
    this.menuService = new MenuService();
    this.tableHight = this.menuService.defaultTableHeight({ header: false });
  }
  // 表格高度
  created() {
    this.gridController.query.params = this.params;
  }
  mounted() {
    return __async(this, null, function* () {
      this.menuService.computedTableHeight("order_container", (height) => {
        this.tableHight = height;
      });
    });
  }
  // 权限组
  // async Permissions() {
  //   try {
  //     // 获取权限
  //     // let Auth = await API.get(`api://base/api/v1/account/auth2`)
  //     // // console.log(Auth,'Auth')
  //     // let userStatus = Auth?.data.status
  //     // let userFrozenReason = Auth?.data.status_describe
  //     // if (
  //     //   localStorage.getItem('userStatus') === FORBIDDEN &&
  //     //   userStatus === 'normal'
  //     // ) {
  //     //   this.$router.push('/')
  //     // }
  //     // if (userStatus === FORBIDDEN) {
  //     //   this.$message({
  //     //     duration: 0,
  //     //     type: 'error',
  //     //     dangerouslyUseHTMLString: true,
  //     //     message: `您的账号已被冻结,<button class="el-button el-button--text no-padding-btn" onclick="window.location.href = '/main'">查看原因</button>`,
  //     //   })
  //     // }
  //     // userFrozenReason = userFrozenReason.split('●').join('<br>●')
  //     // userFrozenReason = userFrozenReason.split('【').join('<br>【')
  //     // userFrozenReason = userFrozenReason.split('】').join('')
  //     // userFrozenReason = userFrozenReason.split('【').join('')
  //     // localStorage.setItem('userStatus', userStatus)
  //     // localStorage.setItem('userFrozenReason', userFrozenReason)
  //   } catch (error) {
  //     await Remove.removeSideBarInfo()
  //     return
  //   }
  // }
  handleDetail(row) {
    this._orderDetail.open(row);
  }
  // 多选选中
  handleSelectionChange(val) {
    this.muchSelectArr = [...val];
  }
  getRowKeys(row) {
    return row.id;
  }
  tableSort(row) {
    let sort = "desc";
    let field = row.prop;
    if (row.order === "descending") {
      sort = "desc";
    }
    if (row.order === "ascending") {
      sort = "asc";
    }
    if (!row.order) {
      sort = "";
      this.params.sort = "";
      this.search();
      return;
    }
    this.params.sort = `${field},${sort}`;
    this.search();
  }
  search(params) {
    return __async(this, null, function* () {
      yield this.gridController.search();
      this._multipleTable.clearSelection();
    });
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date * 1e3);
  }
};
__decorateClass([
  Ref("order-detail")
], order.prototype, "_orderDetail", 2);
__decorateClass([
  Ref("multipleTable")
], order.prototype, "_multipleTable", 2);
order = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid,
      Question,
      orderDetail
    }
  })
], order);
export {
  order as default
};
