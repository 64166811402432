"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, PropSync } from "vue-property-decorator";
import question from "@/views/components/question.vue";
import { Debounce } from "vue-debounce-decorator";
let NumberType = class extends Vue {
  mounted() {
    return __async(this, null, function* () {
    });
  }
  // regions -> areas -> types -> sources -> products
  changeConfig(configName, code) {
    return __async(this, null, function* () {
      yield this._paymentController.changeConfig(configName, code);
      this.computePrice();
    });
  }
  computePrice() {
    this.$emit("changeCompute");
  }
};
__decorateClass([
  PropSync("paymentController")
], NumberType.prototype, "_paymentController", 2);
__decorateClass([
  Debounce(600)
], NumberType.prototype, "computePrice", 1);
NumberType = __decorateClass([
  Component({
    components: {
      question
    }
  })
], NumberType);
export {
  NumberType as default
};
