"use strict";
export const numberListMock = (mock) => {
  mock.onGet("/api/v1/usr/shops").reply((config) => {
    let _response = {};
    if (config.params.is_show === 1) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "",
              "shop_id": "666666",
              //店铺序列号
              "shop_name": "\u6D4B\u8BD5"
              //店铺名称
            },
            {
              "model": "",
              "shop_id": "1234",
              //店铺序列号
              "shop_name": "\u5E97\u94FA\u540D\u5B571"
              //店铺名称
            },
            {
              "model": "",
              "shop_id": "88888888",
              //店铺序列号
              "shop_name": "\u6D4B\u8BD52"
              //店铺名称
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    if (config.params.is_show === 0) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "",
              "shop_id": "789",
              //店铺序列号
              "shop_name": "\u5E97\u94FA\u540D\u5B57\u5206\u9875"
              //店铺名称
            },
            {
              "model": "",
              "shop_id": "456",
              //店铺序列号
              "shop_name": "\u5E97\u94FA\u540D\u5B571"
              //店铺名称
            },
            {
              "model": "",
              "shop_id": "123",
              //店铺序列号
              "shop_name": "\u5E97\u94FA\u540D\u5B572"
              //店铺名称
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    return [200, _response];
  });
};
