"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
import { OrderType } from "@/models/orderType.model";
import UiGrid from "@/components/grid/UiGrid.vue";
import { handleDate } from "@/tools/date";
import API from "@ym-framework/api-utils";
let orderDetail = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialog = new UiDialogController();
    this.data = {};
    this.row = null;
    this.params = {};
    this.loading = false;
    this.orderType = OrderType;
  }
  created() {
  }
  open(row) {
    return __async(this, null, function* () {
      this.row = row;
      yield this.getDetail(row.orderNo);
      this.dialog.visible = true;
    });
  }
  getDetail(id) {
    return __async(this, null, function* () {
      try {
        this.loading = true;
        let response = yield API.get(`api://base.cloud.auth/v1/orders/${id}`);
        this.data = response.data;
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      } finally {
        this.loading = false;
      }
    });
  }
  filterOrderType(type) {
    let _name = "";
    this.orderType.filter(
      (item) => item.status === type && (_name = item.name)
    );
    return _name;
  }
  close() {
    this.dialog.visible = false;
    this.$emit("closeDialog");
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date);
  }
};
orderDetail = __decorateClass([
  Component({
    components: {
      UiDialog,
      UiGrid
    }
  })
], orderDetail);
export {
  orderDetail as default
};
