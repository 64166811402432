"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
let money_verify_dialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.money_verify_dialog = new UiDialogController();
    this.title = "";
    this.loading = false;
    this.OrderDetail = {
      orderNo: "",
      status: "",
      totalCount: 0,
      orderType: "",
      productName: "",
      url: null,
      payPaltform: "",
      detail: ""
    };
  }
  //打开订单弹窗
  open(data) {
    try {
      this.OrderDetail = data;
      this.money_verify_dialog.visible = true;
    } catch (error) {
      if (error.hasOwnProperty("response")) {
        this.$message.error(error.response.data.errors.datas[0].message);
      }
    }
  }
  //取消订单
  close() {
    return __async(this, null, function* () {
      try {
        yield API.delete(`api://base.cloud.auth/v1/orders/${this.OrderDetail.orderNo}`);
        this.$message.success("\u5DF2\u53D6\u6D88\u8BA2\u5355");
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message({
            showClose: true,
            message: error.response.data.errors.datas[0].message,
            type: "error"
          });
        }
      } finally {
        this.money_verify_dialog.visible = false;
      }
    });
  }
  //确认支付
  submit() {
    return __async(this, null, function* () {
      try {
        this.loading = true;
        yield API.put(
          `api://base.cloud.auth/v1/orders/${this.OrderDetail.orderNo}`
        );
        this.$message({
          showClose: true,
          message: "\u4ED8\u6B3E\u6210\u529F",
          type: "success"
        });
        this.money_verify_dialog.visible = false;
        this.$router.push({
          name: "PayConfirm",
          params: { type: "success", coin: this.OrderDetail.totalCount.toString() }
        });
        this.$emit("getCoin");
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message({
            showClose: true,
            message: error.response.data.errors.datas[0].message,
            type: "error"
          });
          this.$router.push({
            name: "PayConfirm",
            params: {
              type: "failed",
              reason: error.response.data.errors.datas[0].message
            }
          });
        }
      } finally {
        this.loading = false;
      }
    });
  }
};
money_verify_dialog = __decorateClass([
  Component({
    components: {
      UiDialog
    }
  })
], money_verify_dialog);
export {
  money_verify_dialog as default
};
