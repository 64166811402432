"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
import UiForm, {
  UiFormAnnotations,
  UiFormController
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import API from "@ym-framework/api-utils";
export class HandleEmail extends UiFormController {
  constructor() {
    super(...arguments);
    this.email = "";
    this.verification_code = "";
  }
}
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: "\u90AE\u7BB1\u4E0D\u80FD\u4E3A\u7A7A",
      trigger: "blur"
    }
  ])
], HandleEmail.prototype, "email", 2);
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: "\u9A8C\u8BC1\u7801\u4E0D\u80FD\u4E3A\u7A7A",
      trigger: "blur"
    }
  ])
], HandleEmail.prototype, "verification_code", 2);
let Email_dialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialog = new UiDialogController();
    this.HandleNumber = new HandleEmail();
    this.title = "\u7ED1\u5B9A\u90AE\u7BB1";
    this.Iscount = false;
    this.bthLoading = false;
    this.count = 60;
    this.guid = "";
    this.submitData = {
      change_type: 0,
      //绑定0/解绑1
      cloud_phone_guids: []
      // 绑定号的guid数组
    };
    this.numberPool = 1;
    //阿里
    this.number_pool = 1;
  }
  created() {
  }
  init() {
    this.HandleNumber.email = "";
    this.HandleNumber.verification_code = "";
    this.submitData.cloud_phone_guids.length = 0;
    this.Iscount = false;
  }
  open(row) {
    return __async(this, null, function* () {
      this.init();
      this.row = row;
      this.dialog.visible = true;
      this.$nextTick(() => {
        this._UiForm._form.resetFields();
      });
    });
  }
  close() {
    this.dialog.visible = false;
    this.$emit("refresh");
  }
  // 获取验证码
  // async getRuleNum() {
  //   if (!this.HandleNumber.email) {
  //     this.$message.error('请输入邮箱')
  //     return
  //   }
  //   try {
  //     await API.post(`api://base/api/v1/phone/email-code`, {
  //       codeType: this.title === '解绑邮箱' ? 'unbind' : 'bind',
  //       email: this.HandleNumber.email,
  //     })
  //     this.Iscount = true
  //     clearInterval(this.timer)
  //     this.count = 60
  //     this.timer = setInterval(() => {
  //       if (this.count === 0) {
  //         this.Iscount = false
  //         this.count = 60
  //         return
  //       }
  //       this.count -= 1
  //     }, 1000)
  //   } catch (error:any) {
  //     if(error.hasOwnProperty('response')){
  //       this.$message({
  //         showClose: true,
  //         message: error.response.data.errors.datas[0].message,
  //         type: 'error',
  //       })
  //     }
  //   }
  // }
  submit() {
    return __async(this, null, function* () {
      this.bthLoading = true;
      try {
        let arr = [];
        arr.push(this.row.id);
        yield API.put(`api://base.cloud.auth/v1/numbers-bind-email`, {
          email: this.HandleNumber.email.trim(),
          ids: arr
        });
        this.$message({
          message: "\u7CFB\u7EDF\u64CD\u4F5C\u4E2D\uFF0C\u8FD9\u53EF\u80FD\u9700\u8981\u4E00\u6BB5\u65F6\u95F4\uFF0C\u8BF7\u8010\u5FC3\u7B49\u5F85\u5E76\u6301\u7EED\u5173\u6CE8\u3002",
          type: "success"
        });
        this.close();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message({
            showClose: true,
            message: error.response.data.errors.datas[0].message,
            type: "error"
          });
        }
      } finally {
        this.bthLoading = false;
      }
    });
  }
};
__decorateClass([
  Ref("form")
], Email_dialog.prototype, "_UiForm", 2);
Email_dialog = __decorateClass([
  Component({
    components: {
      UiDialog,
      UiForm,
      UiSubmit
    }
  })
], Email_dialog);
export {
  Email_dialog as default
};
