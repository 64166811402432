"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
let configAuthority = class extends Vue {
  constructor() {
    super(...arguments);
    this.configAuthority = new UiDialogController();
    this.title = "";
    this.Loading = false;
    this.bthLoading = false;
    this.defaultAuthorityList = [];
    this.AuthorityList = [];
    this.userId = "";
    this.batchUserId = [];
  }
  init() {
  }
  open(row) {
    return __async(this, null, function* () {
      this.configAuthority.visible = true;
      yield this.getDefaultAuth();
      if (row) {
        this.userId = row.id;
        yield this.getAuthority(row.id);
      }
    });
  }
  getDefaultAuth() {
    return __async(this, null, function* () {
      this.Loading = true;
      try {
        let response = yield API.get(`api://base/api/v1/org/all_powers`);
        this.defaultAuthorityList = response.data;
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      } finally {
        this.Loading = false;
      }
    });
  }
  getAuthority(id) {
    return __async(this, null, function* () {
      this.Loading = true;
      try {
        let response = yield API.get(`api://base/api/v1/org/${id}/powers`);
        this.AuthorityList = response.data.powers;
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      } finally {
        this.Loading = false;
      }
    });
  }
  close() {
    this.configAuthority.visible = false;
    this.AuthorityList = [];
    this.$emit("refresh");
  }
  submit() {
    return __async(this, null, function* () {
      this.bthLoading = true;
      try {
        if (this.userId) {
          yield API.put(`api://base/api/v1/org/${this.userId}/powers`, {
            powers: this.AuthorityList
          });
        } else {
          yield API.put(`api://base/api/v1/org/powers`, {
            update_role: this.batchUserId.reduce(
              (pre, cur, index) => {
                pre[index] = {
                  powers: this.AuthorityList,
                  user_id: cur
                };
                return pre;
              },
              []
            )
          });
        }
        this.$message({
          message: "\u64CD\u4F5C\u6210\u529F",
          type: "success"
        });
        this.close();
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      } finally {
        this.bthLoading = false;
      }
    });
  }
};
configAuthority = __decorateClass([
  Component({
    components: {
      UiDialog
    }
  })
], configAuthority);
export {
  configAuthority as default
};
