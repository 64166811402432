"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Mixins } from "vue-property-decorator";
import _ from "lodash";
import Layout from "@/components/Layout.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import MainDetail from "./components/main_detail_dialog.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { handleDate } from "@/tools/date";
import { MenuService } from "@/service/menu.service";
import Question from "./components/question.vue";
import { MessageBox } from "element-ui";
import API from "@ym-framework/api-utils";
import { Debounce } from "vue-debounce-decorator";
import RemarkDialog from "./components/set_remark_dialog.vue";
import BindEmailDialog from "./components/email_dialog.vue";
import { AccountSessionMixins } from "@/utils/account-session";
const _gridController = new GridController(
  new APIDataSource("api://base.cloud.auth/v1/numbers")
);
let Main = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.nullImg = require("@/assets/style/images/kong-vd.b11ecd8e.png");
    this.nullText = "\u6682\u65E0\u4E91\u53F7\u7801";
    this.fullscreenLoading = true;
    this.detailShow = false;
    this.menuService = new MenuService();
    this.batchHandle = true;
    //批量操作显示
    this.gridController = _gridController;
    this.muchSelectArr = [];
    //多选列表
    this.params = {
      search: "",
      //搜索字符串
      sort: "",
      //排序use_date,asc;cloud_phone,desc
      phone_type: "all",
      //类型 （虚拟号：virtual, 运营商：real,全部：all）
      phone_status: "all",
      //状态
      order_number: "",
      //购买订单号
      is_auto_renewal: 0,
      //是否自动续费 0全部1自动续费2非自动续费
      is_passed: 0,
      //是否即将过期 0全部1过期2没有过期
      is_entity: 0,
      //绑定实体号状态 0全部1绑定2未绑定
      tab: 0,
      //第零个tab页传0，第一个传1，第二个传2
      number_pool: null,
      // 号池 1阿里 2华为
      bind_email: null
      // 是否绑定邮箱
    };
    this.tableHight = this.menuService.defaultTableHeight() - 40;
    this.realSwitch = _.debounce((row) => __async(this, null, function* () {
      this.gridController.query.current = 1;
      this.search();
    }), 800);
  }
  // 表格高度
  mounted() {
    return __async(this, null, function* () {
      var _a;
      if ((_a = this.$route.query) == null ? void 0 : _a.hasOwnProperty("jump")) {
        this.$router.push(`/${this.$route.query.jump}`);
        return;
      }
      this._layout.activeMenu = "main";
      this._layout.setActiveMenu("main");
      this.fullscreenLoading = false;
      this.menuService.computedTableHeight("main_container", (height) => {
        this.tableHight = height - 40;
      });
      yield this.search();
    });
  }
  search(params) {
    return __async(this, null, function* () {
      if (!params) {
      }
      this.gridController.query.current = 1;
      yield this.gridController.search();
    });
  }
  getRowKeys(row) {
    return row.id;
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date);
  }
  // 多选框选中状态设置
  checked(row) {
    if (row.status !== "UNSUBSCRIBE") {
      return true;
    }
  }
  // 计算可用天数
  checkCanUseDays(expireDate) {
    const nowDateTime = Math.floor(Date.now() / 1e3);
    const dayDiff = expireDate - nowDateTime;
    const day = Math.floor(dayDiff / 86400);
    return day;
  }
  handleSelectionChange(val) {
    if (val.length === 0) {
      this.batchHandle = true;
    } else {
      this.batchHandle = false;
    }
    this.muchSelectArr = [...val];
  }
  tableSort(row) {
    return __async(this, null, function* () {
      let sort = "desc";
      let field = row.prop;
      if (row.order === "descending") {
        sort = "desc";
      }
      if (row.order === "ascending") {
        sort = "asc";
      }
      if (!row.order) {
        sort = "";
        this.params.sort = "";
        yield this.search();
        return;
      }
      this.params.sort = `${field},${sort}`;
      yield this.search();
    });
  }
  // 绑定实体号
  handleNumber() {
    return __async(this, null, function* () {
    });
  }
  // 切换录音开关
  switchHandle(row) {
    return __async(this, null, function* () {
      yield this.realSwitch(row);
    });
  }
  // 切换自动续费
  switchRenew(row) {
    return __async(this, null, function* () {
      yield this.realAutoRenew(row);
    });
  }
  realAutoRenew(row) {
    return __async(this, null, function* () {
      try {
        let arr = [];
        arr.push(row.id);
        yield API.put(`api://base.cloud.auth/v1/numbers-autoRenew`, {
          "autoRenew": row.autoRenew,
          "ids": arr
        });
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  goBuy() {
    return __async(this, null, function* () {
      try {
        if (!this.$session.enterpriseStatus) {
          yield MessageBox.confirm("\u5F53\u524D\u60A8\u7684\u8D26\u53F7\u5C1A\u672A\u901A\u8FC7\u4F01\u4E1A\u8BA4\u8BC1\uFF0C\u8BF7\u5148\u8FDB\u884C\u4F01\u4E1A\u8BA4\u8BC1\u53CA\u627F\u8BFA\u4E66\u63D0\u4EA4\uFF0C\u5BA1\u6838\u901A\u8FC7\u540E\uFF0C\u5373\u53EF\u5F00\u653E\u5B8C\u6574\u670D\u52A1\u3002", "\u8BA4\u8BC1\u63D0\u793A", {
            confirmButtonClass: "el-button--warning",
            roundButton: true,
            confirmButtonText: "\u524D\u5F80\u8BA4\u8BC1",
            cancelButtonText: "\u53D6\u6D88"
          });
          this.$router.push({ path: "/certification" });
        } else {
          this.$router.push({ path: "/buyMain", query: { title: "toBuy" } });
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
  helpTip() {
    window.open("https://www.kuajingvs.com/help2/7857", "__blank");
  }
  //续费
  renewal(event, row) {
    let guid = [];
    if (event === "batch") {
      this.muchSelectArr.forEach((v) => {
        guid.push(v.id);
      });
    }
    if (event === "every") {
      guid.push(row.id);
    }
    this.$router.push({
      path: "Renewal",
      query: {
        title: "renew",
        guid: JSON.stringify(guid)
      }
    });
    this.batchHandle = true;
  }
  // 操作详情
  handleDetail(row) {
    return __async(this, null, function* () {
      try {
        this.detailShow = true;
        let response = yield API.get(`api://base.cloud.auth/v1/numbers/${row.id}`);
        this.$nextTick(() => {
          this._MainDetail.open(response.data);
        });
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 处理详情关闭
  closeDialog() {
    this.detailShow = false;
  }
  // 刷新页面
  refresh() {
    return __async(this, null, function* () {
      this.gridController.query.current = 1;
      yield this.search();
    });
  }
  // 删除云号弹框
  handleDelete(row) {
    return __async(this, null, function* () {
      try {
        let arr = [row.id];
        yield MessageBox.confirm("\u786E\u5B9A\u8981\u5220\u9664\u6240\u9009\u5DF2\u9000\u8BA2\u4E91\u53F7\u7801\uFF1F\u6B64\u64CD\u4F5C\u65E0\u6CD5\u88AB\u64A4\u9500", "\u5220\u9664\u5DF2\u9000\u8BA2\u4E91\u53F7\u7801", {
          confirmButtonClass: "el-button--warning",
          roundButton: true,
          confirmButtonText: "\u786E\u5B9A",
          cancelButtonText: "\u53D6\u6D88"
        });
        this.$message.success("\u5220\u9664\u6210\u529F");
        yield this.refresh();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 未知方法 为什么要在此处进行认证 获取认证ID
  // async getIccid(id:any){
  //   try {
  //     const result =  await API.get(`api://base/api/v1/phone/real-name-params/${id}`)
  //     return result.data.iccid
  //   } catch (error:any) {
  // if(error.hasOwnProperty('response')){
  //   this.$message.error(error.response.data.errors.datas[0].message)
  // }
  //   }
  // }
  // 解绑实体号
  unbindNumber(row) {
    return __async(this, null, function* () {
      try {
        yield MessageBox.confirm("\u786E\u5B9A\u89E3\u7ED1\u5F53\u524D\u53F7\u7801\u7684\u5B9E\u4F53\u53F7\u5417\uFF1F\u8FD0\u8425\u5546\u5DF2\u518D\u4E0D\u652F\u6301\u7ED1\u5B9A\u5B9E\u4F53\u53F7", "\u89E3\u7ED1\u5B9E\u4F53\u53F7", {
          confirmButtonClass: "el-button--warning",
          roundButton: true,
          confirmButtonText: "\u786E\u5B9A",
          cancelButtonText: "\u53D6\u6D88"
        });
        let ids = [];
        ids.push(row.id);
        yield API.put(`api://base.cloud.auth/v1/numbers-unbind-phone`, {
          ids
        });
        this.$message.success("\u5DF2\u89E3\u7ED1\u5F53\u524D\u53F7\u7801\u5B9E\u4F53\u53F7");
        yield this.refresh();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 绑定实体号
  bindNumber(row) {
    return __async(this, null, function* () {
      try {
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 解绑邮箱
  unbindEmail(row) {
    return __async(this, null, function* () {
      try {
        yield MessageBox.confirm("\u786E\u5B9A\u89E3\u7ED1\u5F53\u524D\u53F7\u7801\u7684\u90AE\u7BB1\u5417\uFF1F", "\u89E3\u7ED1\u90AE\u7BB1", {
          confirmButtonClass: "el-button--warning",
          roundButton: true,
          confirmButtonText: "\u786E\u5B9A",
          cancelButtonText: "\u53D6\u6D88"
        });
        let ids = [];
        ids.push(row.id);
        yield API.put(`api://base.cloud.auth/v1/numbers-unbind-email`, {
          ids
        });
        this.$message.success("\u5DF2\u89E3\u7ED1\u90AE\u7BB1");
        yield this.refresh();
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 绑定邮箱
  bindEmail(row) {
    return __async(this, null, function* () {
      try {
        this._bindEmailDialog.open(row);
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 设置备注
  remark(row) {
    return __async(this, null, function* () {
      try {
        this._remarkDialog.open(row);
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  // 更多
  handleCommand(data) {
    return __async(this, null, function* () {
      switch (data.event) {
        case "verify":
          break;
        case "unbindNumber":
          yield this.unbindNumber(data.item);
          break;
        case "bindNumber":
          yield this.bindNumber(data.item);
          break;
        case "unbindEmail":
          yield this.unbindEmail(data.item);
          break;
        case "bindEmail":
          yield this.bindEmail(data.item);
          break;
        case "remark":
          yield this.remark(data.item);
          break;
      }
    });
  }
};
__decorateClass([
  Ref("main-detail")
], Main.prototype, "_MainDetail", 2);
__decorateClass([
  Ref("remark-dialog")
], Main.prototype, "_remarkDialog", 2);
__decorateClass([
  Ref("bind-email-dialog")
], Main.prototype, "_bindEmailDialog", 2);
__decorateClass([
  Ref("layout")
], Main.prototype, "_layout", 2);
__decorateClass([
  Debounce(800)
], Main.prototype, "realAutoRenew", 1);
Main = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid,
      Question,
      MainDetail,
      RemarkDialog,
      BindEmailDialog
    }
  })
], Main);
export {
  Main as default
};
