"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let authStatusModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.enterprise_status = "";
    this.commitment_status = "";
    this.remark = "";
  }
};
authStatusModel.model = "certification.status.model";
authStatusModel = __decorateClass([
  Annotation.ModelMeta
], authStatusModel);
