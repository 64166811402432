"use strict";
class handle {
  /**
   * 
   * @param format yyyy-mm-dd | yyyy-mm-dd-HH:mm:ss
   * @param now 时间戳
   * @returns yyyy-mm-dd = 2020-02-02 2020-02-02 08:08:12
   */
  formatDate(format = "yyyy-mm-dd", now) {
    now = new Date(now);
    const year = this.addzoer(now.getFullYear());
    const month = this.addzoer(now.getMonth() + 1);
    const date = this.addzoer(now.getDate());
    if (format === "yyyy-mm-dd") {
      return year + "-" + month + "-" + date;
    }
    if (format === "yyyy\u5E74mm\u6708dd\u65E5") {
      return year + "\u5E74" + month + "\u6708" + date + "\u65E5";
    }
    if (format === "yyyy-mm-dd-HH:mm:ss") {
      const hour = this.addzoer(now.getHours());
      const minute = this.addzoer(now.getMinutes());
      const second = this.addzoer(now.getSeconds());
      return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
    }
  }
  addzoer(timE) {
    if (timE < 10) {
      return "0" + timE;
    } else {
      return timE;
    }
  }
}
export const handleDate = new handle();
