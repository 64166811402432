"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { OrderService } from "./buyProducts.service";
import { buyProductAPI } from "../api/buyProduct.api";
export class RenewalService {
  constructor() {
    // 订单
    this.orderService = new OrderService();
    // 续费列表
    this.renewProduct = {
      periods: [],
      numbers: []
    };
    // 续费配置
    this.renewConfig = {
      period: {},
      isAutoRenew: false,
      payPlatform: "COIN",
      ids: []
    };
    // 价格
    this.paymentCost = {
      loading: true,
      nowPrice: 0,
      originPrice: 0
    };
  }
  // 获取续费商品信息
  getRenewProductList(arr) {
    return __async(this, null, function* () {
      this.renewConfig.ids = arr;
      this.renewProduct = yield buyProductAPI.renewProductList(arr);
      this.renewConfig.period = this.renewProduct.periods[0];
    });
  }
  // 计算价格
  computePrice() {
    return __async(this, null, function* () {
      this.paymentCost.loading = true;
      const orderPrice = yield this.orderService.computePrice({
        "type": "NUMBER_RENEW",
        "payPlatform": this.renewConfig.payPlatform,
        "renewNumber": {
          "numbersIds": this.renewConfig.ids,
          "period": this.renewConfig.period.period,
          "periodUnit": this.renewConfig.period.periodUnit
        }
      });
      this.paymentCost.nowPrice = orderPrice.nowPrice;
      this.paymentCost.originPrice = orderPrice.originPrice;
      this.paymentCost.loading = false;
    });
  }
  // 创建续费订单
  renewNumbers() {
    return __async(this, null, function* () {
      this.paymentCost.loading = true;
      let requestDto = {
        "type": "NUMBER_RENEW",
        "payPlatform": this.renewConfig.payPlatform,
        "renewNumber": {
          "numbersIds": this.renewConfig.ids,
          "period": this.renewConfig.period.period,
          "periodUnit": this.renewConfig.period.periodUnit
        },
        "autoRenew": this.renewConfig.isAutoRenew
      };
      const orderResponse = yield this.orderService.buildOrder(requestDto);
      this.paymentCost.loading = false;
      return orderResponse;
    });
  }
}
