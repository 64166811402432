"use strict";
export const Menus = [
  {
    name: "\u6211\u7684\u4E91\u53F7\u7801",
    icon: require("@/assets/style/images/phone.png"),
    activeIcon: require("@/assets/style/images/phone-cative.png"),
    id: "main",
    disabled: false,
    // 'PHONE_LIST'
    powers: [],
    active: false
  },
  {
    name: "\u6211\u7684\u94B1\u5305",
    icon: require("@/assets/style/images/bean.png"),
    activeIcon: require("@/assets/style/images/bean-active.png"),
    id: "recharge",
    disabled: false,
    powers: [],
    active: false
  },
  {
    name: "\u4E91\u53F7\u7801\u540C\u6B65\u8BB0\u5F55",
    icon: require("@/assets/style/images/record.png"),
    activeIcon: require("@/assets/style/images/record-active.png"),
    id: "record",
    disabled: false,
    powers: [],
    active: false
  },
  {
    name: "\u8BA2\u5355\u7BA1\u7406",
    icon: require("@/assets/style/images/order.png"),
    active: false,
    activeIcon: require("@/assets/style/images/order-active.png"),
    // 'ORDER_LIST'
    powers: [],
    id: "costmanage",
    disabled: false
  },
  // {
  //   name: '团队管理',
  //   icon: require('@/assets/style/images/team.png'),
  //   activeIcon: require('@/assets/style/images/team-active.png'),
  //   active: false,
  //   // 'TEAM_NAMAGE'
  //   powers: [],
  //   id: 'teammanage',
  //   disabled: false,
  // },
  {
    name: "\u4F01\u4E1A\u8BA4\u8BC1",
    icon: require("@/assets/style/images/team.png"),
    activeIcon: require("@/assets/style/images/team-active.png"),
    active: false,
    powers: [],
    id: "certification",
    disabled: false
  }
];
export function findParent(key, menus) {
  let parent;
  menus.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        if (child.id === key) {
          parent = item;
        }
      });
    }
  });
  return parent;
}
export function getNoChildMenu(menus) {
  const noChildMenu = [];
  menus.forEach((item) => {
    if (!item.children) {
      noChildMenu.push(item);
    }
  });
  return noChildMenu;
}
