"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
import { GeneralGridResult } from "../core";
export class APIDataSource {
  constructor(url) {
    this._url = url;
  }
  search(searchQuery) {
    return __async(this, null, function* () {
      const _result = new GeneralGridResult();
      try {
        const _grid = yield API.get(this._url, {
          params: __spreadValues({
            search: searchQuery == null ? void 0 : searchQuery.filter,
            sort: searchQuery == null ? void 0 : searchQuery.sort,
            size: searchQuery == null ? void 0 : searchQuery.size,
            page: searchQuery == null ? void 0 : searchQuery.current
          }, searchQuery == null ? void 0 : searchQuery.params)
        });
        for (const _item of _grid.data.result.datas) {
          _result.add(_item);
        }
        _result.page.current = _grid.data.page.current;
        _result.page.total = _grid.data.page.total;
        _result.page.size = _grid.data.page.size;
      } catch (error) {
      }
      return _result;
    });
  }
}
