"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Mixins, PropSync } from "vue-property-decorator";
import { AccountSessionMixins } from "@/utils/account-session";
import { Debounce } from "vue-debounce-decorator";
let NumberConfig = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.pay_modeArr = [
      {
        id: "COIN",
        address: require("@/assets/style/images/pay_jd.png")
      }
      // {
      //   id: "alipay",
      //   address: require("@/assets/style/images/pay_zfb.png"),
      // },
      // {
      //   id: "wechat",
      //   address: require("@/assets/style/images/pay_wx.png"),
      // },
      // {
      //   id: "vd",
      //   address: require("@/assets/style/images/pay_vd.png"),
      // },
    ];
  }
  //改变支付方式
  changePayMode(payMode) {
    this._paymentController.purchaseSelected.platform = payMode;
  }
  //去充值
  goPay() {
    window.open(`https://yunhao.kuajingvs.com/cost?name=cloudNumber`);
  }
  computePrice() {
    this.$emit("changeCompute");
  }
};
__decorateClass([
  PropSync("paymentController")
], NumberConfig.prototype, "_paymentController", 2);
__decorateClass([
  Debounce(600)
], NumberConfig.prototype, "computePrice", 1);
NumberConfig = __decorateClass([
  Component({
    components: {}
  })
], NumberConfig);
export {
  NumberConfig as default
};
