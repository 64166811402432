"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Mixins, Prop } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { handleDate } from "@/tools/date";
import smsDetailDialog from "../sms_detail_dialog.vue";
import filterListDialog from "../filter_list_dialog.vue";
import _ from "lodash";
import BatchOperate from "@/components/elements/BatchOperate.vue";
import { cloudRecordBatch } from "@/configuration/constant/cloud";
import { AccountSessionMixins } from "@/utils/account-session";
const _infogridController = new GridController(
  new APIDataSource("api://base.cloud.auth/v1/sms")
);
let cloudInfoLog = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.nullText = "\u6682\u65E0\u77ED\u4FE1\u8BB0\u5F55";
    this.nullImg = require("@/assets/style/images/kong-vd.b11ecd8e.png");
    this.infogridController = _infogridController;
    // 选中的行
    this.muchSelectArr = [];
    this.params = {
      search: ""
      //搜索字符串
      // start_date: null,
      // end_date: null,
      // message_type: 0, //类型 0全部1发送2接收
      // message_state: 0, //状态 0全部2失败1成功
      // sort: '',
      // current: 1,
    };
    // 批量操作内容
    this.cloudBatch = cloudRecordBatch;
    this.searchEvent = _.debounce(() => {
      this.infogridController.query.current = 1;
      this.search();
    }, 1e3);
  }
  created() {
    this.infogridController.query.params = this.params;
  }
  search() {
    if (this.params.search && this.params.search !== "" && this.params.search !== null) {
      this.infogridController.query.params = {
        "search": `number==${this.params.search}`
      };
    } else {
      this.infogridController.query.params = {};
    }
    this.infogridController.search();
  }
  // 关闭批量操作
  closeBatch() {
    this.muchSelectArr = [];
    this._multipleTable.clearSelection();
  }
  getRowKeys(row) {
    return row.guid;
  }
  refreshList() {
    this.search();
  }
  // 筛选
  HandlefilterList() {
    this._filterListDialog.diff = "\u77ED\u4FE1\u7B5B\u9009";
    this._filterListDialog.open("\u77ED\u4FE1\u7B5B\u9009");
  }
  tableSort(row) {
    let sort = "desc";
    let field = row.prop;
    if (row.order === "descending") {
      sort = "desc";
    }
    if (row.order === "ascending") {
      sort = "asc";
    }
    if (!row.order) {
      sort = "";
      this.params.sort = "";
      this.search();
      return;
    }
    this.params.sort = `${field},${sort}`;
    this.search();
  }
  ListFilter(val) {
    this.params.search = val.search;
    this.params.message_state = val.message_state;
    this.params.start_date = val.timer ? val.timer[0] : null;
    this.params.end_date = val.timer ? val.timer[1] : null;
    this.infogridController.query.current = 1;
    this.search();
  }
  handleCommand(data) {
    if (data.event === "delInfoLog") {
    }
  }
  handleSelectionChange(val) {
    this.muchSelectArr = [...val];
  }
  batchExport() {
    return __async(this, null, function* () {
      let message_guids = [];
      this.muchSelectArr.map((v) => {
        message_guids.push(v.guid);
      });
      try {
        let response = yield API.post(
          `api://base/api/v1/cloud/short_message/import`,
          {
            message_guids
          },
          {
            responseType: "arraybuffer"
          }
        );
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-  officedocument.spreadsheetml.sheet;charset=utf-8"
        });
        let time = new Date().getTime();
        let name = "\u77ED\u4FE1\u8BB0\u5F55 " + this.getDate("yyyy-mm-dd", time) + ".xlsx";
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = name;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      }
    });
  }
  // async checkOut(info: any) {
  //   let _row = _.find(this.infogridController.result.datas, { guid: info.guid })
  //   if (_row !== undefined && info.type === 'message') {
  //     await this.getDetail(_row)
  //   }
  // }
  getDetail(row) {
    return __async(this, null, function* () {
      const coin = this.$session.coin;
      if (coin < 0) {
        this.$message({
          showClose: true,
          message: "\u4E91\u53F7\u7801\u6B20\u8D39\u4FDD\u53F7\uFF0C\u8BF7\u5145\u503C\u540E\uFF0C\u70B9\u51FB\u6211\u7684\u4E91\u53F7\u7801\u9875\u7684\u6062\u590D\u901A\u8BDD\u6309\u94AE\uFF0C\u6062\u590D\u4E91\u540C\u6B65\u8BB0\u5F55\u5185\u5BB9\uFF01",
          type: "error"
        });
        return;
      }
      let _resource = {
        guid: row.id,
        type: "message"
      };
      try {
        let response = yield API.get(`api://base.cloud.auth/v1/sms/${row.id}`);
        let _info = {
          row,
          smsInfo: response == null ? void 0 : response.data
        };
        this._smsDetailDialog.open(_info);
      } catch (error) {
        if (error.hasOwnProperty("response")) {
          this.$message.error(error.response.data.errors.datas[0].message);
        }
      }
    });
  }
  getDate(format, date) {
    return handleDate.formatDate(format, date);
  }
};
__decorateClass([
  Prop(Number)
], cloudInfoLog.prototype, "tableHight", 2);
__decorateClass([
  Ref("smsDetail-dialog")
], cloudInfoLog.prototype, "_smsDetailDialog", 2);
__decorateClass([
  Ref("filterList-dialog")
], cloudInfoLog.prototype, "_filterListDialog", 2);
__decorateClass([
  Ref("multipleTable")
], cloudInfoLog.prototype, "_multipleTable", 2);
cloudInfoLog = __decorateClass([
  Component({
    components: {
      UiGrid,
      smsDetailDialog,
      filterListDialog,
      BatchOperate
    }
  })
], cloudInfoLog);
export {
  cloudInfoLog as default
};
