"use strict";
export const numberListMock = (mock) => {
  mock.onGet("/api/v1/phone/shops").reply((config) => {
    let _response = {};
    if (config.params.is_use === 1) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "phone.cloud.detail",
              "guid": "xxxxx",
              //临时被叫号码ID
              "shop_id": "666666",
              //店铺序列号
              "shop_name": "\u6D4B\u8BD5",
              //店铺名称
              "user_guid": "qqq",
              //关联用户
              "user_name": "qqq",
              //关联用户名
              "is_bind": 1,
              //是否可用 1可用2不可用
              "is_bind_name": "",
              //是否绑定名称
              "bind_time": 16221312e5,
              //关联时间
              "unbind_time": 16221312e5,
              //解除时间
              "unbind_user": "",
              //解除绑定人
              "unbind_user_name": "",
              //解除绑定人名称
              "platform": "eee",
              //所属平台
              "platfrom_en": "aaa",
              "cloud_phone": "rrr",
              //绑定云号码
              "cloud_phone_guid": "sss",
              //云号码ID
              "remark": "\u6211\u662F\u5173\u8054"
              //备注
            },
            {
              "model": "phone.cloud.detail",
              "guid": "xxxxx",
              //临时被叫号码ID
              "shop_id": "88888888",
              //店铺序列号
              "shop_name": "\u6D4B\u8BD52",
              //店铺名称
              "user_guid": "hhh",
              //关联用户
              "user_name": "rrr",
              //关联用户名
              "is_bind": 1,
              //是否可用 1可用2不可用
              "is_bind_name": "",
              //是否绑定名称
              "bind_time": 16221312e5,
              //关联时间
              "unbind_time": 16221312e5,
              //解除时间
              "unbind_user": "",
              //解除绑定人
              "unbind_user_name": "",
              //解除绑定人名称
              "platform": "jjjjj",
              //所属平台
              "platfrom_en": "aaa",
              "cloud_phone": "jjjj",
              //绑定云号码
              "cloud_phone_guid": "kkkk",
              //云号码ID
              "remark": "oooo"
              //备注
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    if (config.params.is_use === 2) {
      _response = {
        "model": "grid.result",
        "result": {
          "model": "data.set",
          "datas": [
            {
              "model": "phone.cloud.detail",
              "guid": "xxxxx",
              //临时被叫号码ID
              "shop_id": "123456",
              //店铺序列号
              "shop_name": "\u6211\u662F\u5DF2\u89E3\u9664",
              //店铺名称
              "user_guid": "qqq",
              //关联用户
              "user_name": "qqq",
              //关联用户名
              "is_bind": 1,
              //是否可用 1可用2不可用
              "is_bind_name": "",
              //是否绑定名称
              "bind_time": 16221312e5,
              //关联时间
              "unbind_time": 16221312e5,
              //解除时间
              "unbind_user": "",
              //解除绑定人
              "unbind_user_name": "4554",
              //解除绑定人名称
              "platform": "eee",
              //所属平台
              "platfrom_en": "aaa",
              "cloud_phone": "rrr",
              //绑定云号码
              "cloud_phone_guid": "sss",
              //云号码ID
              "remark": "123"
              //备注
            },
            {
              "model": "phone.cloud.detail",
              "guid": "xxxxx",
              //临时被叫号码ID
              "shop_id": "ggg",
              //店铺序列号
              "shop_name": "ggg",
              //店铺名称
              "user_guid": "hhh",
              //关联用户
              "user_name": "rrr",
              //关联用户名
              "is_bind": 1,
              //是否可用 1可用2不可用
              "is_bind_name": "",
              //是否绑定名称
              "bind_time": 16221312e5,
              //关联时间
              "unbind_time": 16221312e5,
              //解除时间
              "unbind_user": "",
              //解除绑定人
              "unbind_user_name": "546546",
              //解除绑定人名称
              "platform": "jjjjj",
              //所属平台
              "platfrom_en": "aaa",
              "cloud_phone": "jjjj",
              //绑定云号码
              "cloud_phone_guid": "kkkk",
              //云号码ID
              "remark": "\u6211\u662F\u5DF2\u89E3\u9664"
              //备注
            }
          ],
          "total": 6
        },
        "page": {
          "model": "page",
          "current": 1,
          "total": 1,
          "size": 10
        }
      };
    }
    return [200, _response];
  });
};
