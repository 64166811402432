"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Mixins } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import UiGrid from "@/components/grid/UiGrid.vue";
import cloudInfoLog from "./components/gridComponents/cloudInfoLog.vue";
import { MenuService } from "@/service/menu.service";
import { AccountSessionMixins } from "@/utils/account-session";
let Record = class extends Mixins(AccountSessionMixins) {
  constructor() {
    super(...arguments);
    this.menuService = new MenuService();
    this.tableHight = this.menuService.defaultTableHeight() - 50;
  }
  // 表格高度
  mounted() {
    return __async(this, null, function* () {
      this.menuService.computedTableHeight("record_container", (height) => {
        this.tableHight = height - 50;
      });
    });
  }
  // async Permissions() {
  //   const accountService = new AccountManager()
  //   let res = await accountService.Permissions()
  //   if (res) {
  //     this.userStatus = res.userStatus
  //     this.userFrozenReason = res.userFrozenReason
  //   }
  // }
};
Record = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid,
      cloudInfoLog
    }
  })
], Record);
export {
  Record as default
};
