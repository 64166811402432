"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import UiDialog, { UiDialogController } from "@/components/dialog/UiDialog.vue";
let charge_rule_dialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.charge_rule = new UiDialogController();
    this.loading = false;
    this.showcollapse = true;
    this.collapseShow = false;
    this.tableData = [];
    this.projectData = [
      { charge: "\u6708\u79DF", rules: "\u65B0\u8D2D3\u4E2A\u6708\u8D77\uFF0C\u7EED\u8D39\u53EF\u7EED\u8D391\u4E2A\u6708\u3002" },
      { charge: "\u901A\u8BDD", rules: "\u6839\u636E\u5B9E\u9645\u901A\u8BDD\u65F6\u957F\u8BA1\u8D39\uFF0C\u4E0D\u8DB31\u5206\u949F\u63091\u5206\u949F\u8BA1\u7B97\u3002" },
      {
        charge: "\u77ED\u4FE1",
        rules: "\u2022 \u56FD\u5185\u53F7\u7801\uFF1A\u6309\u7167\u8FD0\u8425\u5546\u89C4\u5219\uFF0C70\u5B57\u7B26\u4E3A1\u6761\u77ED\u4FE1,\u8D85\u8FC7\u5219\u8BA1\u7B97\u4E3A\u591A\u6761\u3002<br>\u2022 \u6D77\u5916\u53F7\u7801\uFF1A<span class='green'>\u6309\u8FD0\u8425\u5546\u5B9E\u9645\u77ED\u4FE1\u6570\u91CF\u8FDB\u884C\u6536\u8D39</span>\uFF0C\u8D85\u8FC7\u5219\u8BA1\u7B97\u4E3A\u591A\u6761\u3002"
      },
      {
        charge: "\u5F00\u5361\u8D39",
        rules: '\u65B0\u8D2D\u4E91\u53F7\u7801\u65F6\uFF0C\u6309\u7167\u53F7\u7801\u6570\u91CF\u6536\u53D6\u5F00\u5361\u8D39\uFF0C<span class="green">\u5F00\u5361\u8D39\u6BCF\u4E2A\u53F7\u7801\u53EA\u968F\u7B2C\u4E00\u4E2A\u6708\u79DF\u6536\u53D6\u4E00\u6B21</span>\uFF0C\u7B2C\u4E8C\u4E2A\u6708\u4E0D\u518D\u6536\u53D6\u3002'
      },
      // {
      //   charge: "退订",
      //   rules: "按照运营商规则，退订号码收取—定费用(购买当月退订免退订费)",
      // },
      { charge: "\u4E91\u540C\u6B65", rules: "\u9650\u514D" },
      { charge: "\u77ED\u4FE1\u62E6\u622A", rules: "\u9650\u514D" }
    ];
  }
  mounted() {
    this.open();
  }
  fatGoods(row) {
    return row.project_name.substring(0, row.project_name.length);
  }
  fatMonthRent(month_pay) {
    return month_pay;
  }
  open() {
    this.getTable();
  }
  getTable() {
    return __async(this, null, function* () {
      try {
        let response = yield API.get(`api://base/api/v1/product/pay/rule`);
        this.tableData = response.data.datas;
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data.errors.datas[0].message,
          type: "error"
        });
      }
    });
  }
  close() {
    return __async(this, null, function* () {
      this.charge_rule.visible = false;
    });
  }
};
charge_rule_dialog = __decorateClass([
  Component({
    components: {
      UiDialog
    }
  })
], charge_rule_dialog);
export {
  charge_rule_dialog as default
};
