"use strict";
export const cloudInfoLog = (mock) => {
  mock.onPost("/api/v1/product/calculate/renew").reply((config) => {
    let _response = {};
    _response = {
      "model": "calculate.renew.model",
      "calculate_req": {
        "cloud_phone_ids": [
          "1",
          "2"
        ],
        "purchase_time": "one_year",
        "pay_mode": "pay_mode",
        "discount_code": "discount_code"
      },
      "product_price": 240
    };
    return [200, _response];
  });
  mock.onPost("/api/v1/order/renew").reply((config) => {
    let _response = {};
    _response = {
      "model": "order.create",
      "message": "success",
      "order_guid": "1654895164985162"
      //订单ID
    };
    return [200, _response];
  });
};
