"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, PropSync, Vue, Ref } from "vue-property-decorator";
export * from "./annotations";
import _ from "lodash";
export var UiFormStatus = /* @__PURE__ */ ((UiFormStatus2) => {
  UiFormStatus2["LOADING"] = "loading";
  UiFormStatus2["COMPLETE"] = "complete";
  return UiFormStatus2;
})(UiFormStatus || {});
export class UiFormController {
  constructor() {
    this._status = "complete" /* COMPLETE */;
  }
  properties() {
    return Object.getOwnPropertyNames(this);
  }
  loading() {
    return this._status === "loading" /* LOADING */;
  }
  complete() {
    this.status = "complete" /* COMPLETE */;
  }
  set status(status) {
    this._status = status;
  }
  get status() {
    return this._status;
  }
  *defaultValues() {
    const _defaultValue = /* @__PURE__ */ new Map();
    for (const _property of this.properties()) {
      if (_property.substr(0, 1) === "_") {
        continue;
      }
      const _has = Reflect.hasMetadata("form.default.value", this, _property);
      if (_has) {
        const _value = Reflect.getMetadata(
          "form.default.value",
          this,
          _property
        );
        _defaultValue.set(_property, _value);
        yield [_property, _value];
      } else {
        yield [_property, null];
      }
    }
    return /* @__PURE__ */ new Map();
  }
  clear() {
    for (const [_property, _value] of this.defaultValues()) {
      _.set(this, _property, _value);
    }
  }
  rules() {
    const rules = {};
    for (const _property of this.properties()) {
      const _has = Reflect.hasMetadata("form.rules", this, _property);
      if (_has) {
        const _value = Reflect.getMetadata("form.rules", this, _property);
        rules[_property] = _value;
      }
    }
    return rules;
  }
}
export class SearchFormController extends UiFormController {
  *queryEntries() {
    for (const _property of this.properties()) {
      const _has = Reflect.hasMetadata("form.queryFactory", this, _property);
      if (_has) {
        const _queryFactory = Reflect.getMetadata(
          "form.queryFactory",
          this,
          _property
        );
        yield [_property, _queryFactory(_property, _.get(this, _property))];
      } else {
        if (_property.substr(0, 1) === "_") {
          continue;
        }
        yield [_property, [_property, _.get(this, _property)]];
      }
    }
  }
  query() {
    const querys = /* @__PURE__ */ new Map();
    for (const _property of this.properties()) {
      const _has = Reflect.hasMetadata("form.queryFactory", this, _property);
      if (_has) {
        const _queryFactory = Reflect.getMetadata(
          "form.queryFactory",
          this,
          _property
        );
        const [_query_name, _query_value] = _queryFactory(
          _property,
          _.get(this, _property)
        );
        if (_query_value === null) {
          continue;
        }
        querys.set(_query_name, _query_value);
      } else {
        const _value = _.get(this, _property);
        if (_property.substr(0, 1) === "_") {
          continue;
        }
        if (_value === null) {
          continue;
        }
        querys.set(_property, _value);
      }
    }
    return querys;
  }
}
let UiForm = class extends Vue {
  submit() {
    return __async(this, null, function* () {
      try {
        let _validate = yield this._form.validate();
        this._controller.status = "loading" /* LOADING */;
        this.$emit("submit", this._controller);
      } catch (error) {
        console.log(error);
      } finally {
        if (this._autoSubmitComplete) {
          this._controller.complete();
        }
      }
    });
  }
};
__decorateClass([
  Ref("_form-element")
], UiForm.prototype, "_form", 2);
__decorateClass([
  PropSync("controller", { default: () => {
    return new UiFormController();
  } })
], UiForm.prototype, "_controller", 2);
__decorateClass([
  PropSync("autoSubmitComplete", { type: Boolean, default: true })
], UiForm.prototype, "_autoSubmitComplete", 2);
UiForm = __decorateClass([
  Component
], UiForm);
export {
  UiForm as default
};
